import {
  SET_HAS_CHATS_WITH,
  SET_MESSAGES,
  SET_MESSAGES_WITH_EXISTING,
  SET_OPEN_CHAT,
} from "./constants";

const initialState = {
  messages: [],
  hasChatsWith: [],
  openChat: false,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_MESSAGES: {
      return {
        ...state,
        messages: payload,
      };
    }

    case SET_MESSAGES_WITH_EXISTING: {
      return {
        ...state,
        messages: [...state.messages, payload],
      };
    }

    case SET_HAS_CHATS_WITH: {
      return {
        ...state,
        hasChatsWith: payload || [],
      };
    }

    case SET_OPEN_CHAT: {
      return {
        ...state,
        openChat: payload,
      };
    }

    default:
      return state;
  }
}
