import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore, onSnapshot, doc, query, setDoc, addDoc, where, orderBy, limit, updateDoc } from "firebase/firestore";
import axios from "../../library/axios";
import { config } from "../../library/common/firebase";
import { asyncActionFinish, asyncActionStart, asyncActionSuccess } from "../App/actions";
import {
  SET_ALIBABA_CATEGORIES,
  SET_HAS_CHATS_WITH,
  SET_ALIBABA_SELECTED_CATEGORY,
  SET_ALIBABA_SELECTED_PRODUCT,
  SET_ALIBABA_SELECTED_SHOP,
  SET_ALIBABA_SPOTLIGHT,
  SET_J4U,
  SET_MESSAGES,
  SET_MESSAGES_WITH_EXISTING,
  SET_AUTO_RESPONSE,
  SET_USER_CHATS,
  SET_USER_CHAT_MESSAGES,
  SET_MERCHANT_PRODUCTS,
  SET_INTERNATIONAL_PRODUCT,
  OPEN_INTERNATIONAL_CHATBOX,
  CLOSE_INTERNATIONAL_CHATBOX,
  SET_INTERNATIONAL_CATEGORIES,
  SET_NEW_ARRIVALS,
  SET_SELECTED_CONV_MERCHANT,
} from "./constants";

const app = initializeApp(config());
const db = getFirestore(app);

export const getInternationalMerchants = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/onboardedmerchants");
      return data;
    } catch (error) {
      console.log(error);
    }
  };
};
export const getHome = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/alibaba/home");
      dispatch(setCategories(response.data.categories || []));
      dispatch(setJust4U(response.data.trending.products || []));
      dispatch(setSpotlight(response.data.details.spotlight));
      const { data } = await axios.get(`/alibaba/newarrival`);
      dispatch(setNewArrivals(data.list));
      console.log(data.list);
    } catch (error) {
      console.log(error);
    }
  };
};

export const createAffiliateClick = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(`/affiliates/click/${id}`);
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
};
export const getCategories = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/alibaba/categories_new");
      dispatch(setInternationalCategories(response.data));
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
};
export const getInvoiceStats = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/invoices/stats");
      return data;
    } catch (error) {
      console.log(error);
    }
  };
};

export const getFinancials = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/invoices/financials");
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
};

export const getInventory = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/invoices/inventory");
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
};

export const getInvoiceStatsFiltered = (dateRange) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/invoices/statsfiltered", { params: dateRange });
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
};
export const updateAutoResponse = (id, form) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      const { data } = await axios.put(`/autoresponse/${id}`, form);
      console.log(data);
      dispatch(getAutoResponseAdmin());
      dispatch(asyncActionSuccess({ message: "Auto Response Settings Updated." }));
    } catch (error) {
      console.log(error);
    }
  };
};
export const addFAQItem = (id, item) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(`/autoresponse/addfaq/${id}`, { item });
      console.log(data);
      dispatch(getAutoResponseAdmin());
    } catch (error) {
      console.log(error);
    }
  };
};
export const addFAQItemSeller = (id, item, merchant) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(`/autoresponse/addfaq/${id}`, { item });
      console.log(data);
      dispatch(getAutoResponseSeller(merchant));
    } catch (error) {
      console.log(error);
    }
  };
};
export const addInitialItemSeller = (id, item, merchant) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(`/autoresponse/addinitial/${id}`, { item });
      console.log(data);
      dispatch(getAutoResponseSeller(merchant));
    } catch (error) {
      console.log(error);
    }
  };
};
export const addInitialItem = (id, item) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      const { data } = await axios.put(`/autoresponse/addinitial/${id}`, { item });
      console.log(data);
      dispatch(getAutoResponseAdmin());
      dispatch(asyncActionSuccess({ message: "Auto Response Settings Updated." }));
    } catch (error) {
      console.log(error);
    }
  };
};
export const getAutoResponseAdmin = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/autoresponse/getadmin/details");
      console.log(data);
      dispatch(setAutoResponse(data.autoResponse));
      return data.autoResponse;
    } catch (error) {
      console.log(error);
    }
  };
};
export const getAutoResponseSeller = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/autoresponse/seller/${id}`);
      dispatch(setAutoResponse(data.autoresponse));
    } catch (error) {
      console.log(error);
    }
  };
};
export const saveAutoResponseSeller = (id, form) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`/autoresponse/seller/${id}`, form);
      console.log(data);
      dispatch(setAutoResponse(data.autoresponse));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getUserChats = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/messages/${id}`);
      dispatch(setUserChats(data.conversations));
      return data.conversations;
    } catch (error) {
      console.log(error);
    }
  };
};
export const getUserChatMessages = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/messages/list/${id}`);
      dispatch(setUserChatMessages(data.messages));
    } catch (error) {
      console.log(error);
    }
  };
};

export const sendUserMessage = (body, merchantMode) => {
  return async (dispatch) => {
    try {
      const convRef = doc(db, "merchantmessages", body.user);
      console.log(convRef);
      const messageRef = doc(db, "merchantmessages", body.user, "merchants", body.storeName, "messages", Date.now().toString());
      await setDoc(convRef, {
        merchants: [body.merchant],
        timestamp: Date.now(),
      });
      await setDoc(doc(db, "merchantmessages", body.user, "merchants", body.storeName), {
        storeName: body.storeName,
        timestamp: Date.now(),
        merchant: body.merchant,
        user: body.user,
        type: body.messageType,
        lastMessage: body.message,
        store: body.storeName,
        hasNew: true,
      });
      await setDoc(messageRef, {
        ...body,
        timestamp: Date.now(),
      });
      const { data } = await axios.post(`/messages`, body);
      dispatch(getUserChats(merchantMode ? body.merchant : body.user));
      dispatch(getUserChatMessages(data.message.conversationId));
    } catch (error) {
      console.log(error);
    }
  };
};

export const markConversationAsRead = (id, userId) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.put(`/messages/markasread/${id}`);
      dispatch(getUserChats(userId));
      dispatch(getUserChatMessages(id));
    } catch (error) {
      console.log(error);
    }
  };
};
export const getProduct = (url) => {
  return async (dispatch) => {
    try {
      dispatch(setSelectedProduct({}));
      const response = await axios.get(`/alibaba/product?url=${url}`);
      dispatch(setSelectedProduct(response.data.product || {}));
      dispatch(setInternationalProduct(response.data.new_product));
      return response.data.new_product;
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const getCategory = (url) => {
  return async (dispatch) => {
    try {
      dispatch(setSelectedCategory({}));
      const response = await axios.get(`/alibaba/categories?url=${url}`);
      dispatch(setSelectedCategory(response.data || {}));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const getSearch = (query) => {
  return async (dispatch) => {
    try {
      dispatch(setSelectedCategory({}));
      const response = await axios.get(`/alibaba/search/${query}`);
      console.log(response.data);
      dispatch(setSelectedCategory(response.data || {}));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const getSupplierSearch = (query) => {
  return async (dispatch) => {
    try {
      dispatch(setSelectedCategory({}));
      const response = await axios.get(`/alibaba/search-suppliers/${query}`);
      console.log(response.data);
      return response.data || [];
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const getShop = (url) => {
  return async (dispatch) => {
    try {
      dispatch(setSelectedShop({}));
      const response = await axios.get(`/alibaba/supplier?url=${url}`);
      dispatch(setSelectedShop(response.data || {}));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const sendMessage = (msg) => {
  return async (dispatch) => {
    try {
      const response = await axios.post("/users/send-message", msg);

      return response;
    } catch (error) {
      console.log(error, "error sending msg");
    }
  };
};

export const getMessagesWith = (userId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/users/get-messages-with/" + userId);
      console.log(response.data);
      dispatch(setMessages(response.data || []));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getHasMessagesWith = () => {
  return async (dispatch) => {
    try {
      console.log("HIT!");
      const response = await axios.get("/users/message/list/with");
      console.log(response, "response getting chat list");
      dispatch(setHasChatsWith(response.data));
      return response;
    } catch (error) {
      console.log(error, "error getting");
    }
  };
};

export const getShopDetails = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/alibaba/shop/${id}`);
      dispatch(setSelectedShop(data.merchant));
      dispatch(getMerchantProducts(data.merchant.link));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getProductCSView = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/alibaba/productcsview/${id}`);
      console.log(data);
      dispatch(setInternationalProduct(data.product));
      return data.product;
    } catch (error) {
      console.log(error);
    }
  };
};
export const getMerchantProducts = (link) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      dispatch(setMerchantProducts([]));
      const { data } = await axios.get(`/alibaba/merchantproducts/${link}`);
      console.log(data);
      dispatch(setMerchantProducts(data.products));
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
    }
  };
};
export const getLocalMerchantDashboard = () => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      const { data } = await axios.get(`/orders/localmerchantoverview/dashboard`);
      console.log(data);
      dispatch(asyncActionFinish());
      return data;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionFinish());
    }
  };
};

export const getLocalMerchantDashboardDates = () => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      const { data } = await axios.get("/orders/localmerchantoverview/dates");
      console.log(data);
      dispatch(asyncActionFinish());
      return data;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionFinish());
    }
  };
};

export const getLocalMerchantDashboardPrevious = (start, end) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      const { data } = await axios.get(`/orders/localmerchantoverview/previous?start=${start}&end=${end}`);
      console.log(data);
      dispatch(asyncActionFinish());
      return data;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionFinish());
    }
  };
};

export const getRecentDisbursement = (period) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      const { data } = await axios.get(`/disbursement/recent-releases/statement?period=${period}`);
      console.log(data);
      dispatch(asyncActionFinish());
      return data;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionFinish());
    }
  };
};

export const getPreviousReleasesByPeriod = (period) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      const { data } = await axios.get(`/disbursement/releases/statement?period=${period}`);
      console.log(data);
      dispatch(asyncActionFinish());
      return data;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionFinish());
    }
  };
};

// start v2
export const getMerchantsWithOrders = () => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      const { data } = await axios.get("/orders/localmerchantoverview/merchants");
      dispatch(asyncActionFinish());
      return data;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionFinish());
    }
  };
};

export const getLocalMerchantDashboardPreviousByMerchant = (start, end, merchant, filter = "ALL") => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      let url = `/orders/localmerchantoverview/previousbymerchant?merchant=${merchant}&filter=${filter}`;
      if (start) {
        url += `&start=${start}`;
      }
      if (end) {
        url += `&end=${end}`;
      }
      const { data } = await axios.get(url);
      console.log(data);
      dispatch(asyncActionFinish());
      return data;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionFinish());
    }
  };
};
// end v2

export const setMerchantProducts = (payload) => {
  return {
    type: SET_MERCHANT_PRODUCTS,
    payload,
  };
};

export const setHasChatsWith = (payload) => {
  return {
    type: SET_HAS_CHATS_WITH,
    payload,
  };
};
export const setMessages = (payload) => {
  return {
    type: SET_MESSAGES,
    payload,
  };
};
export const setMessagesWithExisting = (payload) => {
  return {
    type: SET_MESSAGES_WITH_EXISTING,
    payload,
  };
};
// export const updateShopItems = (url) => {
//     return async (dispatch) => {
//         try {
//             dispatch()
//         } catch (error) {
//             console.log(error.message)
//         }
//     }
// }

export const setSelectedShop = (payload) => {
  return {
    type: SET_ALIBABA_SELECTED_SHOP,
    payload,
  };
};

export const setCategories = (payload) => {
  return {
    type: SET_ALIBABA_CATEGORIES,
    payload,
  };
};

export const setJust4U = (payload) => {
  return {
    type: SET_J4U,
    payload,
  };
};

export const setSelectedProduct = (payload) => {
  return {
    type: SET_ALIBABA_SELECTED_PRODUCT,
    payload,
  };
};

export const setSelectedCategory = (payload) => {
  return {
    type: SET_ALIBABA_SELECTED_CATEGORY,
    payload,
  };
};

export const setSpotlight = (payload) => {
  return {
    type: SET_ALIBABA_SPOTLIGHT,
    payload,
  };
};

export const setAutoResponse = (payload) => {
  return {
    type: SET_AUTO_RESPONSE,
    payload,
  };
};

export const setUserChats = (payload) => {
  return {
    type: SET_USER_CHATS,
    payload,
  };
};

export const setUserChatMessages = (payload) => {
  return {
    type: SET_USER_CHAT_MESSAGES,
    payload,
  };
};

export const setInternationalProduct = (payload) => {
  return {
    type: SET_INTERNATIONAL_PRODUCT,
    payload,
  };
};

export const openInternationalChatBox = () => {
  return {
    type: OPEN_INTERNATIONAL_CHATBOX,
  };
};
export const closeInternationalChatBox = () => {
  return {
    type: CLOSE_INTERNATIONAL_CHATBOX,
  };
};

export const setInternationalCategories = (payload) => {
  return {
    type: SET_INTERNATIONAL_CATEGORIES,
    payload,
  };
};

export const setNewArrivals = (payload) => {
  return {
    type: SET_NEW_ARRIVALS,
    payload,
  };
};

export const setSelectedConvMerchant = (payload) => {
  return {
    type: SET_SELECTED_CONV_MERCHANT,
    payload,
  };
};
