import { SET_CATEGORIES, SET_PRODUCTS, SET_PRODUCTS_BY_CATEGORIES, SET_FOR_YOU, SET_PLACES_CATEGORIES } from "./constants";

const initialState = {
  categories: [],
  placesCategories: [],
  products: [],
  forYou: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_CATEGORIES: {
      return {
        ...state,
        categories: payload,
      };
    }
    case SET_PLACES_CATEGORIES: {
      return {
        ...state,
        placesCategories: payload,
      };
    }

    case SET_PRODUCTS: {
      return {
        ...state,
        products: payload,
      };
    }

    case SET_PRODUCTS_BY_CATEGORIES: {
      return {
        ...state,
        products: payload,
      };
    }

    case SET_FOR_YOU: {
      return {
        ...state,
        forYou: payload,
      };
    }

    default:
      return state;
  }
}
