import {
  HANDLE_POINTS_DECREASE,
  HANDLE_STACK_WITHDRAWAL,
  NEW_AFFILIATE_MEMBER,
  SET_AFFILIATE_MEMBERSHIP_ORDER,
  SET_MY_WITHDRAWAL_HISTORY,
  SET_ORDERS_BY_AFFILIATELINKS,
  SET_POINTHISTORY,
  SET_POINTS,
  SET_TRANSACTION_DETAILS,
  SET_USERS_WITHDRAWALS,
  SET_USER_STATS,
} from "./constants";

const initialState = {
  points: {},
  myWithdrawalHistory: [],
  withdrawals: [],
  affiliateMembershipOrders: {},
  newAffiliateMember: {},
  transactionDetails: {},
  orderMadebyAffiliatelinks: [],
  pointHistory: [],
  userStats: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_POINTS: {
      return {
        ...state,
        points: payload,
      };
    }

    case HANDLE_POINTS_DECREASE: {
      return {
        ...state,
        points: {
          ...state.points,
          points: state.points.points - payload,
        },
      };
    }

    case SET_MY_WITHDRAWAL_HISTORY: {
      return {
        ...state,
        myWithdrawalHistory: payload,
      };
    }

    case HANDLE_STACK_WITHDRAWAL: {
      return {
        ...state,
        myWithdrawalHistory: [payload, ...state.myWithdrawalHistory],
      };
    }

    case SET_USERS_WITHDRAWALS: {
      return {
        ...state,
        withdrawals: payload,
      };
    }

    case SET_AFFILIATE_MEMBERSHIP_ORDER: {
      return {
        ...state,
        affiliateMembershipOrders: payload,
      };
    }

    case NEW_AFFILIATE_MEMBER: {
      return {
        ...state,
        newAffiliateMember: payload,
      };
    }
    case SET_TRANSACTION_DETAILS: {
      return {
        ...state,
        transactionDetails: payload,
      };
    }

    case SET_ORDERS_BY_AFFILIATELINKS: {
      return {
        ...state,
        orderMadebyAffiliatelinks: payload,
      };
    }

    case SET_POINTHISTORY: {
      return {
        ...state,
        pointHistory: payload,
      };
    }
    case SET_USER_STATS: {
      return {
        ...state,
        userStats: payload,
      };
    }
    default:
      return state;
  }
}
