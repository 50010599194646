import {
    SET_DEALS,
    SET_SELECTED_DEAL,
    SET_ESTIMATES,
    SET_SELECTED_ESTIMATE,
    SET_INVOICES,
    SET_SELECTED_INVOICE,
  } from "./constants";
  
  const initialState = {
    deals: [],
    selectedDeal: {},
    estimates: [],
    selectedEstimate: {},
    invoices: [],
    selectedInvoices: {},
  };
  
  export default function reducer(state = initialState, { type, payload }) {
    switch (type) {
      case SET_DEALS: {
        return {
          ...state,
          deals: payload,
        };
      }
  
      case SET_SELECTED_DEAL: {
        return {
          ...state,
          selectedDeal: payload,
        };
      }
  
      case SET_ESTIMATES: {
        return {
          ...state,
          estimates: payload,
        };
      }
  
      case SET_SELECTED_ESTIMATE: {
        return {
          ...state,
          selectedEstimate: payload,
        };
      }
      case SET_INVOICES: {
        return {
          ...state,
          invoices: payload,
        };
      }
  
      case SET_SELECTED_INVOICE: {
        return {
          ...state,
          selectedInvoices: payload,
        };
      }
  
      default:
        return state;
    }
  }
  