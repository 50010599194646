export const ASYNC_ACTION_START = "ASYNC_ACTION_START";
export const ASYNC_ACTION_FINISH = "ASYNC_ACTION_FINISH";
export const ASYNC_ACTION_ERROR = "ASYNC_ACTION_ERROR";
export const APP_LOADED = "APP_LOADED";
export const SET_SHOW_SIDEBAR = "SET_SHOW_SIDEBAR";
export const SET_SHOW_PROGRESS = "SET_SHOW_PROGRESS";
export const SET_PROGRESS = "SET_PROGRESS";
export const SET_PREVIEW = "SET_PREVIEW";
export const TOGGLE_SHOW_LIVE_SIDEBAR = "TOGGLE_SHOW_LIVE_SIDEBAR";
export const SET_ALERT_DIALOG = "SET_ALERT_DIALOG";
export const TOGGLE_NAVIGATION_LAYOUT = "TOGGLE_NAVIGATION_LAYOUT";
export const SET_APP_SETTINGS = "SET_APP_SETTINGS";
export const SET_BANNERS = "SET_BANNERS";
export const SET_DOCUMENTS = "SET_DOCUMENTS";
export const SET_MERCHANT_CHATS = "SET_MERCHANT_CHATS";
export const SET_SELECTED_MERCHANT_CHAT = "SET_SELECTED_MERCHANT_CHAT";
export const SET_SELECTED_CHAT = "SET_SELECTED_CHAT";
export const OPEN_CHATBOX = "OPEN_CHATBOX";
export const CLOSE_CHATBOX = "CLOSE_CHATBOX";
export const SET_ADMIN_VOUCHERS = "SET_ADMIN_VOUCHERS";
export const LOADING_START = "LOADING_START";
export const LOADING_FINISH = "LOADING_FINISH";
