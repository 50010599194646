import { SET_INVENTORY, SET_PRODUCT_INVENTORY } from "./constants";

const initialState = {
  inventory: [],
  productInventory: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_INVENTORY:
      return { ...state, inventory: payload || [] };
    case SET_PRODUCT_INVENTORY:
      return { ...state, productInventory: payload || [] };
    default:
      return state;
  }
}
