import {
    SET_CONVERSATIONS,
    SET_SELECTED_CONVERSATION,
    SET_MESSAGES,
    ADD_TO_MESSAGES,
    MARK_AS_READ
 } from "./constants";
const initialState = {
  conversations: [],
  selectedConversation: null,
  messages: []
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case MARK_AS_READ: {
      return {
        ...state, 
        conversations: state.conversations.map((a) => {
          if (a._id === payload) {
            return {...a, hasNew: false}
          }
          return a
        })
      }
    }
    case SET_CONVERSATIONS: {
      return {
        ...state,
        conversations: payload,
      };
    }

    case SET_SELECTED_CONVERSATION: {
      return {
        ...state,
        selectedConversation: payload,
      };
    }
    case SET_MESSAGES: {
      return {
        ...state,
        messages: payload,
      };
    }
    case ADD_TO_MESSAGES: {
      return {
        ...state,
        messages: [...state.messages, payload],
      };
    }

    default:
      return state;
  }
}
