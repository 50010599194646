import {SET_J4U, SET_ALIBABA_CATEGORIES, SET_ALIBABA_SELECTED_PRODUCT, SET_ALIBABA_SELECTED_CATEGORY, SET_ALIBABA_SELECTED_SHOP, SET_ALIBABA_SPOTLIGHT, SET_MESSAGES, SET_MESSAGES_WITH_EXISTING, SET_HAS_CHATS_WITH, SET_AUTO_RESPONSE, SET_USER_CHATS, SET_USER_CHAT_MESSAGES, SET_MERCHANT_PRODUCTS, SET_INTERNATIONAL_PRODUCT, OPEN_INTERNATIONAL_CHATBOX, CLOSE_INTERNATIONAL_CHATBOX, SET_INTERNATIONAL_CATEGORIES, SET_NEW_ARRIVALS, SET_SELECTED_CONV_MERCHANT} from './constants'

const initialState = {
    categories : [], 
    just4u: [], 
    spotlight: [],
    selectedProduct: {},
    selectedCategory: {},
    selectedShop: {},
    messages: [],
    hasChatsWith: [],
    autoResponse: null, 
    userChats: [],
    userChatMessages: [], 
    merchantProducts: [],
    internationalProduct: null, 
    internationalchatbox: false, 
    internationalCategories : [], 
    newArrivals : [],
    selectedConvMerchant : null
}

export default function reducer(state = initialState, {type, payload}) {
    switch (type) {
        case SET_SELECTED_CONV_MERCHANT: {
            return {
                ...state, 
                selectedConvMerchant: payload
            }
        }
        case SET_NEW_ARRIVALS: {
            return {
                ...state, 
                newArrivals: payload
            }
        }
        case SET_INTERNATIONAL_CATEGORIES: {
            return {
                ...state, 
                internationalCategories: payload
            }
        }
        case OPEN_INTERNATIONAL_CHATBOX: {
            return {
                ...state,
                internationalchatbox: true
            }
        }
        case CLOSE_INTERNATIONAL_CHATBOX: {
            return {
                ...state, 
                internationalchatbox: false
            }
        }
        case SET_INTERNATIONAL_PRODUCT: {
            return {
                ...state, 
                internationalProduct: payload
            }
        }
        case SET_MERCHANT_PRODUCTS:{
            return {
                ...state, 
                merchantProducts: payload
            }
        }
        case SET_USER_CHATS: {
            return {
                ...state, 
                userChats: payload
            }
        }
        case SET_USER_CHAT_MESSAGES: {
            return {
                ...state, 
                userChatMessages: payload
            }
        }
        case SET_AUTO_RESPONSE: {
            return {
                ...state, 
                autoResponse: payload
            }
        }
        case SET_MESSAGES_WITH_EXISTING: {
            return {
              ...state,
              messages: [...state.messages, payload],
            };
          }
        case SET_HAS_CHATS_WITH: {
            return {
                ...state,
                hasChatsWith: payload || [],
            };
        }
        case SET_MESSAGES: {
            return {
              ...state,
              messages: payload,
            };
          }
        case SET_J4U: {
            return {
                ...state, 
                just4u: payload
            }
        }
        case SET_ALIBABA_CATEGORIES: {
            return {
                ...state, 
                categories: payload
            }
        }
        case SET_ALIBABA_SELECTED_PRODUCT: {
            return {
                ...state, 
                selectedProduct: payload
            }
        }
        case SET_ALIBABA_SELECTED_CATEGORY: {
            return {
                ...state, 
                selectedCategory: payload
            }
        }
        case SET_ALIBABA_SELECTED_SHOP: {
            return {
                ...state, 
                selectedShop: payload
            }
        }
        case SET_ALIBABA_SPOTLIGHT: {
            return {
                ...state, 
                spotlight: payload
            }
        }
        
        default:
            return state;
    }
}