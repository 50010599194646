import {
  SET_SITE,
  SET_PRODUCTS,
  SET_VIEWS,
  SET_SALES_AND_ORDERS,
  SET_SELECTED_VIEWS,
  SET_PRODUCT_RANKINGS,
  SET_BANK_ACCOUNTS,
  SET_STORE_VOUCHERS,
  SET_STORE_VALID_VOUCHERS,
  SET_MERCHANT_PRODUCTS,
  SET_MERCHANT_DISBURSEMENT,
} from "./constants";
const initialState = {
  site: null,
  products: [],
  views: null,
  salesandorders: null,
  selectedViews: null,
  productRankings: null,
  bankAccounts: [],
  storeVouchers: [],
  storeValidVouchers: [],
  merchantProducts: {},
  disbursement: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_MERCHANT_PRODUCTS: {
      return {
        ...state,
        merchantProducts: payload,
      };
    }
    case SET_MERCHANT_DISBURSEMENT: {
      return {
        ...state,
        disbursement: payload,
      };
    }
    case SET_STORE_VOUCHERS: {
      return {
        ...state,
        storeVouchers: payload,
      };
    }

    case SET_STORE_VALID_VOUCHERS: {
      return {
        ...state,
        storeValidVouchers: payload,
      };
    }

    case SET_BANK_ACCOUNTS: {
      return {
        ...state,
        bankAccounts: payload,
      };
    }
    case SET_PRODUCT_RANKINGS: {
      return {
        ...state,
        productRankings: payload,
      };
    }
    case SET_SALES_AND_ORDERS: {
      return {
        ...state,
        salesandorders: payload,
      };
    }
    case SET_SELECTED_VIEWS: {
      return {
        ...state,
        selectedViews: payload,
      };
    }
    case SET_VIEWS: {
      return {
        ...state,
        views: payload,
      };
    }
    case SET_SITE: {
      return {
        ...state,
        site: payload,
      };
    }

    case SET_PRODUCTS: {
      return {
        ...state,
        products: payload,
      };
    }

    default:
      return state;
  }
}
