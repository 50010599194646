import { SET_ESTIMATE, REMOVE_SELECTED_ESTIMATE, SET_ESTIMATES } from "./constants";


const initialState = {
    selectedEstimate : {}, 
    count: 0, 
    estimates: []
}

export default function reducer(state = initialState, {type, payload}) {
    switch (type) {
        case SET_ESTIMATES: {
            return {
                ...state, 
                count: payload.count, 
                estimates: payload.estimates.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            }
        }
        case SET_ESTIMATE: {
            return {
              ...state,
              selectedEstimate: payload,
            };
        }
        case REMOVE_SELECTED_ESTIMATE: {
            return {
              ...state,
              selectedEstimate: {} ,
            };
        }
        default:
            return state;
    }
}