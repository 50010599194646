export const SET_PRODUCT = "SET_PRODUCT";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_PRODUCT_CATEGORY = "SET_PRODUCT_CATEGORY";
export const SET_PRODUCT_CATEGORIES = "SET_PRODUCT_CATEGORIES";
export const SET_PRODUCT_PLACES_CATEGORIES = "SET_PRODUCT_PLACES_CATEGORIES";
export const SET_PRODUCTS_BY_CATEGORIES = "SET_PRODUCT_BY_CATEGORIES";
export const SET_PRODUCT_REVIEWS = "SET_PRODUCT_REVIEWS";
export const SET_BRANDS = "SET_BRANDS";
export const SET_PRICE_MATCHES = "SET_PRICE_MATCHES";
export const SET_SEARCHED_PRODUCTS = "SET_SEARCHED_PRODUCTS";
export const SET_RELATED_PRODUCTS = "SET_RELATED_PRODUCTS";
export const SET_NEW_PROD = "SET_NEW_PROD";
export const SET_PRODUCTS_WITH_CATEGORIES = "SET_PRODUCTS_WITH_CATEGORIES";
