import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import mainReducer from "./mainReducer";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { verifyAuth } from "../../modules/Auth/actions";

export const history = createBrowserHistory();

export function configureStore() {
  let composeEnhancers = compose;
  if (process.env.NODE_ENV !== "production") {
    composeEnhancers = composeWithDevTools;
  }

  const store = createStore(
    (state, action) => mainReducer(state, action, history),
    {},
    composeEnhancers(applyMiddleware(thunk))
  );

  store.dispatch(verifyAuth(store));

  return store;
}
