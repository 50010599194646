import queryString from "querystring";
import htmlParse from "html-react-parser";
import axios from "axios";
import { format, isPast, isToday } from "date-fns";
import intervalToDuration from "date-fns/intervalToDuration";

export function sanitize_encrypted(string) {
  return string.replace(/\//g, "@").replace(/\+/g, "*");
}

export function sanitize_decrypted(string) {
  return string.replace(/@/g, "/").replace(/\*/g, "+");
}

export function parser(html, className = "", options) {
  return htmlParse(`<div ${className ? `class='${className}'` : ""}>${html}</div>`, options);
}

export const getUrlParams = () => {
  const search = window.location.href.split("?")[1];
  return queryString.parse(search);
};

export const objToFormData = (obj) => {
  let formData = new FormData();
  Object.keys(obj).map((key) => {
    // if (typeof obj[key] === "object" && Object.keys(obj[key]).length > 1) {
    if (typeof obj[key] === "object" && obj[key].length > 0) {
      for (const key2 of Object.keys(obj[key])) {
        formData.append(key, obj[key][key2]);
      }
    } else {
      formData.append(key, obj[key]);
    }
  });
  return formData;
};

export const incrementString = (text, prev = "") => {
  // return incrementedString
  if (prev) {
    text = prev;
  }
  let string = text.replace(/[0-9]/g, "");
  let len = text.length - string.length;
  let str = text.slice(string.length) || "0";

  str = (parseInt(str) + 1).toString();

  while (str.length < len) {
    str = "0" + str;
  }

  return string.concat(str);
};

export const getCombination = (arr) => {
  if (arr.length === 1) {
    return arr[0];
  } else {
    let ans = [];

    // recur with the rest of the array.
    let otherCases = getCombination(arr.slice(1));
    for (let i = 0; i < otherCases.length; i++) {
      for (let j = 0; j < arr[0].length; j++) {
        ans.push(arr[0][j] + " / " + otherCases[i]);
      }
    }
    return ans;
  }
};

export const getVariantsCombination = (parts) => {
  if (parts.length === 1) {
    let result = parts[0].values.map((a) => {
      return {
        name: a,
        variant: [{ label: parts[0].name, value: a }],
      };
    });
    console.log("getVariantsCombination", result);
    return result;
  } else {
    parts = parts.map((a) =>
      a.values.map((b) => {
        return {
          label: a.name,
          value: b,
        };
      })
    );
    let result = parts.reduce((a, b) => a.reduce((r, v) => r.concat(b.map((w) => [].concat(v, w))), []));
    result = result.map((a) => {
      return {
        name: a.map((b) => b.value).join(" / "),
        variant: a,
      };
    });
    console.log("getVariantsCombination", result);
    return result;
  }
};

export const numberWithCommas = (x = 0, fixed = 2) => {
  // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return Number(toNumberString(x))
    .toFixed(fixed)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const toNumberString = (val = 0) => {
  // return val.toString().replace(/,/g, '');
  return val.toString().replace(/[^\d.-]*/g, "");
};

export const getAnonymous = (name) => {
  let splits = name.split(" ");
  return `${splits[0][0]}${"".padStart(splits[0].length - 1, "*")}`;
};

export const splitCamelCase = (word) => {
  let output,
    i,
    l,
    capRe = /[A-Z]/;
  if (typeof word !== "string") {
    throw new Error('The "word" parameter must be a string.');
  }
  output = [];
  for (i = 0, l = word.length; i < l; i += 1) {
    if (i === 0) {
      output.push(word[i].toUpperCase());
    } else {
      if (i > 0 && capRe.test(word[i])) {
        output.push(" ");
      }
      output.push(word[i]);
    }
  }
  return output.join("");
};

export const isImage = (string) => {
  if (/(jpeg|jpg|gif|png|webp)$/.test(string)) {
    return true;
  }
  return false;
};
export const sanitizeFileString = (string) => {
  return string.replace("https://marketdeals-bucket.s3.ap-southeast-1.amazonaws.com/images/", "");
};
export const truncate = (input) => (input.length > 30 ? `${input.substring(0, 30)}...` : input);

export const moneyFormat = (num) => {
  return parseFloat(num).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

export const seaFreightMultiplier = 13000;

export const airFreightMultiplier = 14000;

export const getSignature = (amount, referenceNo) => {
  const message = `x_access_key${process.env.REACT_APP_SWIFTPAY_ACCESS_KEY}x_amount${amount}x_currencyPHPx_reference_no${referenceNo}`;
  return message;
};

export const buildPaymentLink = (signature, reference_no, amount) => {
  return `https://api.pay.sandbox.live.swiftpay.ph/api/bootstrap?x_access_key=${process.env.REACT_APP_SWIFTPAY_ACCESS_KEY}&x_reference_no=${reference_no}&x_amount=${amount}&x_currency=PHP&signature=${signature}`;
};

export const convertDollarToPrice = (string) => {
  console.log(string);
  return parseFloat(string.replace(/\$/g, ""));
};
export const convertToPHP = async (price) => {
  const res = await axios.get("https://free.currconv.com/api/v7/convert?q=USD_PHP&compact=ultra&apiKey=8a7956516e964ce36fc2");
  console.log(res.data.USD_PHP * price);
  const finalPrice = parseFloat(price) * res.data.USD_PHP;
  console.log(finalPrice);
  return finalPrice;
};

export const getTimeOfChat = (date) => {
  const durationFromToday = intervalToDuration({
    start: new Date(),
    end: new Date(date),
  });

  //check if from this year and month
  if (durationFromToday.years === 0 && durationFromToday.months === 0) {
    //check if within the week
    if (durationFromToday.days < 7) {
      //check if within the day
      if (durationFromToday.days === 0) {
        return isToday(new Date(date)) ? format(new Date(date), "p") : "Yesterday";
      }
      return format(new Date(date), "ccc");
    }
    return format(new Date(date), "LLL d");
  }
};

export const formatPriceRange = (price) => {
  const initial = price.split("-")[0];
  const parsed = initial.replace("US$", "");
  return parseFloat(parsed.replace(/[^\d\.\-]/g, ""));
};

export const priceAsc = (a, b) => formatPriceRange(a.price) - formatPriceRange(b.price);
export const priceDesc = (a, b) => formatPriceRange(b.price) - formatPriceRange(a.price);

var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "PHP",
});

export const currencyFormatter = (value) => {
  return formatter.format(value);
};
