import React, { useEffect } from "react";
import io from "socket.io-client";
import { useSelector } from "react-redux";
export const SocketContext = React.createContext();
const SocketProvider = SocketContext.Provider;
const socketPoint = process.env.REACT_APP_SOCKET_URL;
// let socket = io(socketPoint);

export const GlobalSocket = ({ children }) => {
  //const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  /* const {
    sendMessage,
    lastMessage,
    readyState,
    lastJsonMessage
  } = useWebSocket('wss://9rkjdnxh2f.execute-api.ap-southeast-1.amazonaws.com/dev');

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  sendMessage({action: 'sending-message', message: {id: 1, text: 'test'}})

  console.log(connectionStatus)
  console.log(lastMessage)
  console.log(lastJsonMessage) */

  // useEffect(() => {
  //   if (user) {
  //     console.log(user)
  //     socket.emit("user", user.userId);
  //     return () => socket.disconnect();
  //   }
  // }, [user]);

  // return <SocketProvider value={{ socket }}>{children}</SocketProvider>;
  return children;
};
