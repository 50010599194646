import axios from "../../library/axios";
import { SET_ESTIMATE, SET_ESTIMATES } from "./constants";

export const createEstimate = (data) => {
  return async (dispatch) => {
    try {
      console.log(data, "before");
      const response = await axios.post("/estimates", data);
      dispatch(setEstimate(response.data.estimate));
      console.log(response.data.estimate, "after");
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const getEstimates = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/estimates`);
      dispatch(setEstimates(data));
      return data.estimates;
    } catch (error) {
      console.log(error.message);
    }
  };
};
export const resendVerification = (user) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(`/users/resend-verification`, user);
      console.log(data);
      return data;
    } catch (error) {
      console.log(error.message);
    }
  };
};
export const getEstimate = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/estimates/${id}`);
      if (response.data.userAccount) {
        dispatch(setEstimate({ ...response.data.estimate, userAccount: response.data.userAccount }));
      } else {
        dispatch(setEstimate(response.data.estimate));
      }
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const convertToInvoice = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post("/invoices", data);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const updateEstimate = (id, data) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/estimates/${id}`, data);
      return response.data;
    } catch (error) {
      console.log(error.message);
    }
  };
};
export const setEstimates = (payload) => {
  return {
    type: SET_ESTIMATES,
    payload,
  };
};
export const setEstimate = (payload) => {
  return {
    type: SET_ESTIMATE,
    payload,
  };
};

export const fetchExchangeRates = () => {
  return async (dispatch) => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("apikey", "EGHblLBFieULh8Z0FSwf17mghso3zDvU");

      const requestOptions = {
        method: "GET",
        redirect: "follow",
        headers: myHeaders,
      };

      let getRate = await fetch("https://api.apilayer.com/exchangerates_data/latest?symbols=PHP&base=USD", requestOptions)
        .then((response) => response.json())
        .catch((error) => console.log("error", error));

      return getRate;
    } catch (error) {
      console.log(error.message);
    }
  };
};
