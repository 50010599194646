import { asyncActionError, asyncActionFinish, asyncActionStart, asyncActionSuccess, getAppSettings, setAppLoaded } from "../App/actions";
import * as authTypes from "./constants";
import axios from "../../library/axios";
import jwt from "jsonwebtoken";
import { fetchFromStorage, removeFromStorage, saveToStorage } from "../../library/utilities/Storage";
import { toast } from "react-toastify";
import { setMerchant } from "../User/actions";
import { getAllConversations } from "../Messages/actions";
import { errors } from "../../library/common/constants";

export const getUserVouchers = (id) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/uservouchers/user/${id}`);
      return data;
    } catch (error) {
      console.log(error);
    }
  };
};
export const signIn = ({ email, password }, user = null, history, redirect) => {
  return async (dispatch) => {
    console.log(redirect);
    dispatch(asyncActionStart());
    try {
      if (user) {
        dispatch({ type: authTypes.SIGN_IN_USER, payload: user });
      } else {
        let result = await axios.post("auth", { email, password });
        console.log(result.data);
        const { accessToken, refreshToken } = result.data;

        saveToStorage(authTypes.ACCESS_TOKEN, accessToken);
        saveToStorage(authTypes.REFRESH_TOKEN, refreshToken);

        let user = jwt.decode(accessToken);
        console.log("user from jwtdecode", user);
        if (user.merchant) {
          console.log("hasMerchant", user.merchant);
          dispatch(setMerchant(user.merchant));
        }
        dispatch(getAllConversations());
        dispatch({ type: authTypes.SIGN_IN_USER, payload: user });
        dispatch(getAppSettings());
        if (user.merchant) {
          history.push(`/my-store/dashboard`);
        }
        if (user.permissionLevel === 5) {
          history.push(`/warehouse`);
        }

        if (user.permissionLevel === 4096) {
          history.push(`/admin`);
        }
      }

      dispatch(asyncActionFinish());

      if (redirect) history.push(redirect);
    } catch (error) {
      console.log("error");
      dispatch(asyncActionError("User not found or invalid password"));
    }
  };
};

export const handleGoogleSignIn = (data) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const tokenId = data.tokenId;

      let result = await axios.post("/auth/google-login", { tokenId });
      console.log(result.data, "actions response");
      const { accessToken, refreshToken } = result.data;

      saveToStorage(authTypes.ACCESS_TOKEN, accessToken);
      saveToStorage(authTypes.REFRESH_TOKEN, refreshToken);

      let user = jwt.decode(accessToken);
      console.log(user);
      dispatch({ type: authTypes.SIGN_IN_USER, payload: user });
      dispatch(getAppSettings());

      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error, "error actions sign in google");
      dispatch(asyncActionFinish());
    }
  };
  //
};

export const handleAppleSignin = (data) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      let result = await axios.post("/auth/apple-login", { data });
      console.log(result.data, "actions response");
      const { accessToken, refreshToken } = result.data;

      saveToStorage(authTypes.ACCESS_TOKEN, accessToken);
      saveToStorage(authTypes.REFRESH_TOKEN, refreshToken);

      let user = jwt.decode(accessToken);
      console.log(user);
      dispatch({ type: authTypes.SIGN_IN_USER, payload: user });
      dispatch(getAppSettings());

      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionFinish());
      console.log(error, "error actions sign in apple");
    }
  };
  //
};

export const signUp = (data, history, redirect) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());

    try {
      // dispatch({ type: authTypes.SIGN_IN_USER, payload: result.user });
      let result = await axios.post("users", data);
      console.log(result.data);
      const { _id } = result.data;

      dispatch(asyncActionFinish());
      if (_id) {
        toast.success("Sign Up successful");

        if (redirect) history.push(redirect);
      } else {
        toast.error("Unable to Sign Up");
      }
    } catch (error) {
      dispatch(asyncActionFinish());
      if (error?.message === "Network Error") {
        return toast.error(errors.NETWORK_ERROR);
      }
      toast.error(errors.DUPLICATE_USER);
    }
  };
};

export const signOut = () => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      // dispatch({ type: authTypes.SIGN_OUT_USER });
      removeFromStorage(authTypes.ACCESS_TOKEN);
      removeFromStorage(authTypes.REFRESH_TOKEN);
      window.location.replace("/");
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export function verifyAuth(store) {
  return async (dispatch) => {
    try {
      let accessToken = fetchFromStorage(authTypes.ACCESS_TOKEN);

      if (accessToken) {
        let user = jwt.decode(accessToken);
        dispatch({ type: authTypes.SIGN_IN_USER, payload: user });
        dispatch(getAppSettings());
      }
    } catch (error) {}
    dispatch(setAppLoaded());
  };
}

export function forgotPassword(email) {
  return async (dispatch) => {
    dispatch(asyncActionStart());

    try {
      const response = await axios.post("/users/forgot-password", { email });
      console.log(response, "response");
      dispatch(asyncActionFinish());
      dispatch(asyncActionSuccess({ message: `Email sent to ${email}` }));
      return response;
    } catch (error) {
      console.log(error, "error");
      dispatch(asyncActionError(error));
      return false;
    }
  };
}

export function forgotPasswordAdmin(email) {
  return async (dispatch) => {
    dispatch(asyncActionStart());

    try {
      const response = await axios.post("/users/forgot-password-admin", { email });
      console.log(response, "response");
      dispatch(asyncActionFinish());
      dispatch(asyncActionSuccess({ message: "Password Reset sent to Admin." }));
      return response;
    } catch (error) {
      console.log(error, "error");
      dispatch(asyncActionError(error));
      return false;
    }
  };
}

export function changePassword(data, history, redirect) {
  return async (dispatch) => {
    dispatch(asyncActionStart());

    try {
      const response = await axios.post("/users/change-password", data);
      console.log(response);
      dispatch(asyncActionFinish());

      if (response.status === 200) {
        toast.success("Password changed");
        if (redirect) history.push(redirect);
      }
    } catch (error) {
      console.log(error, "error");
      dispatch(asyncActionError(error));
    }
  };
}

export function resetPassword(data) {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const response = await axios.post("/users/reset-password", data);
      console.log(response, "response");
      if (response) {
        dispatch(asyncActionSuccess({ message: "Password Changed. Please sign in again." }));
      }
      return response;
    } catch (error) {
      console.log(error, "error");
      dispatch(asyncActionError(error));
      return false;
    }
  };
}

export function getUserDetails(id) {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const res = await axios.get(`/users/${id}`);
      console.log(res.data);
      dispatch(updateUserDetailsAction(res.data));
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error, "error");
      dispatch(asyncActionError(error));
    }
  };
}

export function getUserDetailsX(id) {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const res = await axios.get(`/users/${id}`);
      console.log(res.data);
      dispatch(asyncActionFinish());
      return res.data;
    } catch (error) {
      console.log(error, "error");
      dispatch(asyncActionError(error));
    }
  };
}

export function updateUserDetails(id, data, isAdmin = false) {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      delete data.password;
      delete data.merchant;
      const res = await axios.patch(`/users/${id}`, data);
      dispatch(asyncActionSuccess({ message: "User Profile Updated" }));
      dispatch(updateUserDetailsAction(res.data));

      console.log("response =>", res.data);
      let user = jwt.decode(res.data.accessToken);
      saveToStorage(authTypes.ACCESS_TOKEN, res.data.accessToken);
      dispatch({ type: authTypes.SIGN_IN_USER, payload: user });
      console.log(user);

      return true;
    } catch (error) {
      console.log(error, "error");
      dispatch(asyncActionError(error));
      return false;
    }
  };
}

export function updateUserDetailsX(id, data) {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      delete data.password;
      delete data.merchant;
      const res = await axios.patch(`/users/${id}`, data);
      dispatch(asyncActionSuccess({ message: "User Profile Updated" }));
      return res.data;
    } catch (error) {
      console.log(error, "error");
      dispatch(asyncActionError(error));
      return false;
    }
  };
}
export function updateAuthUser(payload) {
  return async (dispatch) => {
    dispatch({ type: authTypes.UPDATE_AUTH_USER, payload });
  };
}
export function updateUserDetailsAction(payload) {
  return async (dispatch) => {
    dispatch({ type: authTypes.UPDATE_USER_DETAILS, payload });
  };
}
