import axios from "axios";
import { cloneDeep } from "lodash";
import * as authTypes from "../../modules/Auth/constants";
import { fetchFromStorage } from "../utilities/Storage";

const axiosInstance = axios.create({
  baseURL: process.env.NODE_ENV === "production" ? process.env.REACT_APP_BASE_API_URL : "http://localhost:5001/api/v1",
  // baseURL:  process.env.REACT_APP_BASE_API_URL,

  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(async (config) => {
  const clonedConfig = cloneDeep(config);
  const token = fetchFromStorage(authTypes.ACCESS_TOKEN);
  if (token) {
    clonedConfig.headers.common = {
      Authorization: `Bearer ${token}`,
    };
  }

  return clonedConfig;
});

export default axiosInstance;
