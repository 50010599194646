import React, { lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Redirect, Route, Switch, useLocation } from "react-router-dom";
import * as routes from "./constants";
import PrivateRoute from "./PrivateRoute";

const Home = lazy(() => import("../layouts/HomeLayout"));
const SignIn = lazy(() => import("../../modules/Auth/components/SignIn"));
const SignUp = lazy(() => import("../../modules/Auth/components/SignUp"));
const ChangePassword = lazy(() => import("../../modules/Auth/components/ChangePassword"));
const ApplyMerchant = lazy(() => import("../../modules/Auth/components/ApplyMerchant"));
const AdminLayout = lazy(() => import("../layouts/AdminLayout"));
const MerchantLayout = lazy(() => import("../layouts/MerchantLayout"));

const MyAddressBook = lazy(() => import("../../modules/User/components/MyAddressBook"));
const MyAddressBookList = lazy(() => import("../../modules/User/components/MyAddressBookList"));

const Orders = lazy(() => import("../../modules/Order/components/Orders"));
const Order = lazy(() => import("../../modules/Order/components/Order"));

// TESTING COMPONENTS
const MerchantMode = lazy(() => import("../../components/Navbars/MerchantMode"));

const StoreFrontView = lazy(() => import("../../modules/Merchant/components/StoreFrontView"));

const ProductCustomerView = lazy(() => import("../../modules/Product/components/ProductCustomerView"));
const ProductsByCategory = lazy(() => import("../../modules/Product/components/ProductsByCategory"));

const Chat = lazy(() => import("../../modules/Chat/components/Chat"));

const ShareAndEarn = lazy(() => import("../../modules/Affiliate/components/ShareAndEarn"));

const AlibabaHome = lazy(() => import("../../modules/AlibabaProducts/components/NewHome"));
const AlibabaProduct = lazy(() => import("../../modules/AlibabaProducts/components/Product"));
const AlibabaProductsByCategory = lazy(() => import("../../modules/AlibabaProducts/components/ProductsByCategory"));
const AlibabaProductSearch = lazy(() => import("../../modules/AlibabaProducts/components/SearchResults"));
const AlibabaSupplierSearch = lazy(() => import("../../modules/AlibabaProducts/components/SearchSupplier"));
const AlibabaProductNSearch = lazy(() => import("../../modules/AlibabaProducts/components/NewSearchResults"));
const AlibabaShop = lazy(() => import("../../modules/AlibabaProducts/components/Shop"));
const Conversations = lazy(() => import("../../modules/Messages/components/Conversations"));
const ProductCSView = lazy(() => import("../../modules/AlibabaProducts/components/ProductCustomerView"));

const Estimate = lazy(() => import("../../modules/Estimate/components/Estimate"));
const Invoice = lazy(() => import("../../modules/Invoices/components/Invoice"));
const Deals = lazy(() => import("../../modules/Deals/components/Deals"));
const InvoicePayments = lazy(() => import("../../modules/Payment/components/InvoicePayments"));
const MyAccount = lazy(() => import("../../modules/User/components/Account"));
const DeleteAccount = lazy(() => import("../../modules/User/components/DeleteAccount"));
const VerificationSuccess = lazy(() => import("../../modules/Auth/components/VerificationSuccess"));
const Quotations = lazy(() => import("../../modules/User/components/Quotations"));
const MerchantChats = lazy(() => import("../../modules/App/components/MerchantMessages"));

const Register = lazy(() => import("../../modules/Auth/components/Register"));
const AffiliateDashboard = lazy(() => import("../../modules/Affiliate/components/AffiliateDashboard"));
const Warehouse = lazy(() => import("../../modules/Warehouse/components/Warehouse"));

const { ResetPassword } = lazy(() => import("../../modules/Auth/components/ResetPassword"));
const Checkout = lazy(() => import("../../modules/Checkout/Checkout"));
const PaypalPayment = lazy(() => import("../../modules/Payment/components/PaypalPayment"));
const SearchedProducts = lazy(() => import("../../modules/Product/components/SearchedProducts"));
const TermsAndConditions = lazy(() => import("../../modules/Terms/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("../../modules/Terms/PrivacyPolicy"));
const TermsofSale = lazy(() => import("../../modules/Terms/TermsofSale"));
const CompanyProfile = lazy(() => import("../../modules/Terms/CompanyProfile"));
const MissionVision = lazy(() => import("../../modules/Terms/MissionVision"));
const FAQs = lazy(() => import("../../modules/Terms/FAQs"));
const OrderTracker = lazy(() => import("../../modules/Terms/OrderTracker"));
const AffiliateProgram = lazy(() => import("../../modules/Affiliate/components/AffiliateProgram"));
const AffiliateMembershipPayment = lazy(() => import("../../modules/Payment/components/AffiliateMembershipPayment"));
const AffiliatePaymentRedirectPage = lazy(() => import("../../modules/Affiliate/components/AffiliatePaymentRedirectPage"));
const ChatRepLayout = lazy(() => import("../layouts/ChatRepLayout"));
const Blog = lazy(() => import("../layouts/BlogLayout/blog-temp/Blog"));
const PageNotFound = lazy(() => import("./PageNotFound"));

const Routes = () => {
  const location = useLocation();
  const { from } = location.state || { from: { pathname: routes.HOME } };

  const { isLoggedIn, user } = useSelector((state) => state.auth);

  return (
    <div style={{ display: "flex", flex: 1, flexDirection: "column", height: "100vh" }}>
      <BrowserRouter>
        <Switch>
          <Route exact path="/termsandconditions" component={TermsAndConditions} />
          <Route exact path="/privacypolicy" component={PrivacyPolicy} />
          <Route exact path="/termsofsale" component={TermsofSale} />
          <Route exact path="/aboutus" component={CompanyProfile} />
          <Route exact path={"/blog"} component={Blog} />
          {/* <Route exact path={routes.BLOGQUERY} component={Blog} /> */}
          <Route exact path="/missionvision" component={MissionVision} />
          <Route exact path="/faqs" component={FAQs} />
          <Route exact path="/ordertracker" component={OrderTracker} />

          <Route exact path={routes.LOCALHOME} render={(props) => <Home isLocal={true} {...props} />} />
          <Route exact path={[routes.HOME, routes.AFFILIATE_LINK]} component={AlibabaHome} />
          <Route exact path={routes.SIGN_IN} component={SignIn} />
          <Route exact path={routes.SIGN_UP} component={SignUp} />
          <Route exact path="/register" component={Register} />
          <Route exact path={routes.RESET_PASSWORD} component={ResetPassword} />
          <Route exact path={routes.APPLY_MERCHANT} component={ApplyMerchant} />
          <Route exact path={routes.STORE_FRONT} component={StoreFrontView} />
          <Route exact path={routes.PRODUCT_DETAILED} component={ProductCustomerView} />

          <Route exact path={routes.ALIBABA_PRODUCTS_HOME} component={AlibabaHome} />
          <Route exact path={routes.ALIBABA_PRODUCT} component={AlibabaProduct} />
          <Route exact path={routes.ALIBABA_PRODUCT_CS_VIEW} component={ProductCSView} />
          <Route exact path={routes.ALIBABA_PRODUCTS_CATEGORY} component={AlibabaProductsByCategory} />
          <Route exact path={routes.ALIBABA_PRODUCT_SEARCH} component={AlibabaProductSearch} />
          <Route exact path={routes.ALIBABA_SUPPLIER_SEARCH} component={AlibabaSupplierSearch} />
          <Route exact path={routes.ALIBABA_PRODUCT_NSEARCH} component={AlibabaProductNSearch} />
          <Route exact path={routes.ALIBABA_SHOP} component={AlibabaShop} />
          <Route exact path={routes.MESSAGES} component={Conversations} />
          <Route exact path={routes.INVOICEPAYMENTS} component={InvoicePayments} />
          <Route exact path={routes.MY_ACCOUNT} component={MyAccount} />
          <Route exact path={routes.DELETE_ACCOUNT} component={DeleteAccount} />

          <Route exact path={routes.VERIFICATIONSUCCESS} component={VerificationSuccess} />

          <Route exact path={routes.ESTIMATE} component={Estimate} />
          <Route exact path={routes.INVOICE} component={Invoice} />
          <Route exact path={routes.DEALS} component={Deals} />

          <Route exact path={routes.PRODUCTS_BY_CATEGORY} component={ProductsByCategory} />

          <Route exact path={routes.SEARCH + "/:search"} component={SearchedProducts} />

          <PrivateRoute path="/affiliate" component={AffiliateDashboard} isLoggedIn={isLoggedIn && !user.isAdmin} />
          <PrivateRoute exact path="/affiliate-program" isLoggedIn={isLoggedIn} component={AffiliateProgram} />
          <PrivateRoute path={routes.CHANGE_PASSWORD} component={ChangePassword} isLoggedIn={isLoggedIn} />
          <PrivateRoute path={routes.ADMIN} component={AdminLayout} isLoggedIn={isLoggedIn && user.isAdmin} />
          {/* <PrivateRoute path={routes.CHATREP} component={ChatRepLayout} isLoggedIn={isLoggedIn && user.permissionLevel === 4096 && user.isAdmin} /> */}

          <PrivateRoute path={routes.QUOTATIONS} component={Quotations} isLoggedIn={isLoggedIn && user.isAdmin} />
          <PrivateRoute path={routes.MERCHANT_CHATS} component={MerchantChats} isLoggedIn={isLoggedIn && user.isAdmin} />
          <PrivateRoute path={routes.MY_STORE} component={MerchantLayout} isLoggedIn={isLoggedIn && user.permissionLevel === 4} />
          <PrivateRoute path={routes.WAREHOUSE} component={Warehouse} isLoggedIn={isLoggedIn && user.permissionLevel === 5} />

          <PrivateRoute path={routes.CHAT} component={Chat} isLoggedIn={isLoggedIn} />
          <PrivateRoute path={routes.CHECKOUT} component={Checkout} isLoggedIn={isLoggedIn} />
          <PrivateRoute path={routes.MY_ADDRESS_BOOK + "/create"} component={MyAddressBook} isLoggedIn={isLoggedIn} />
          <PrivateRoute path={routes.MY_ADDRESS_BOOK} isLoggedIn={isLoggedIn} component={MyAddressBookList} />
          <PrivateRoute path={routes.SHARE_AND_EARN} isLoggedIn={isLoggedIn} component={ShareAndEarn} />
          <PrivateRoute path={routes.ORDERS + "/:id"} isLoggedIn={isLoggedIn} component={Order} />
          <PrivateRoute path={routes.ORDERS} isLoggedIn={isLoggedIn} component={Orders} />

          {/* <Redirect from={routes.HOME} to={routes.ACCOUNT} /> */}

          {/* Payments */}
          <Route path={routes.PAYMENTS_PAYPAL} component={PaypalPayment} />
          <Route path={routes.PAYMENT_AFFILIATE_MEMBERSHIP} component={AffiliateMembershipPayment} />
          <Route path={routes.PAYMENT_AFFILIATE_REDIRECT_} component={AffiliatePaymentRedirectPage} />

          <Route exact path="/sandbox" component={MerchantMode} />
          <Route path="/404" component={PageNotFound} />
          <Redirect to="/404" />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default Routes;
