import React, { Suspense } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "./App.scss";

import { ThemeProvider, useTheme } from "@material-ui/core/styles";
import { defaultTheme } from "./resources/styles/theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import Routes from "./main/routes";
import { useSelector } from "react-redux";
import Loader from "./components/Loader";
import AppAlertDialog from "./components/AppAlertDialog";
import Cart from "./components/Cart";

import { GlobalSocket } from "./modules/App/GlobalSocket";
import * as routes from "./main/routes/constants";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import ChatBox from "./components/ChatBox/ChatBox";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import "react-photo-view/dist/index.css";
import { PhotoProvider } from "react-photo-view";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-multi-carousel/lib/styles.css";
import "antd/dist/antd.css";
import AuthChecker from "./components/AuthChecker/AuthChecker";
import "./resources/styles/agrid.css";
import { HelmetProvider } from "react-helmet-async";

function App() {
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);
  const { initialized, loading, showProgress, progress, alertDialog } = useSelector((state) => state.app);

  if (!initialized) return <Loader progress={progress} showProgress={showProgress} />;

  return (
    <HelmetProvider>
      <ThemeProvider theme={defaultTheme(theme)}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Suspense fallback={<Loader progress={progress} showProgress={showProgress} />}>
            <CssBaseline />
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Loader open={loading} progress={progress} showProgress={showProgress} />
            <AppAlertDialog
              {...alertDialog}
              open={alertDialog !== null && alertDialog.content !== null}
              // title={alertDialog?.title}
              // content={alertDialog?.content}
              // agreeText={alertDialog?.agreeText}
              // disagreeText={alertDialog?.disagreeText}
              // onAgree={alertDialog?.onAgree}
              // onDisagree={alertDialog?.onDisagree}
              // showActions={alertDialog?.showActions}
              // fullScreen={alertDialog?.fullScreen}
              // fullWidth={alertDialog?.fullWidth}
              // maxWidth={alertDialog?.maxWidth}
            />

            <Routes />
            <AuthChecker />
            {user && !window.location.pathname.includes(routes.MY_STORE) && !window.location.pathname.includes(routes.PAYMENTS)}
            {user?.isAdmin !== true && <ChatBox />}
          </Suspense>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
