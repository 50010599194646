import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Backdrop from "@material-ui/core/Backdrop";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    zIndex: theme.zIndex.drawer + 9998,
    color: "#fff",
    // backgroundColor: "rgba(0,0,0,0.5)",
  },
}));

function Loader({ open = true, handleClose, progress, showProgress }) {
  const classes = useStyles();

  return (
    <div className="custom-loader">
      <Backdrop className={classes.backdrop} open={open} onClick={handleClose}>
        <Box position="relative" display="inline-flex">
          <CircularProgress
            variant="indeterminate"
            disableShrink
            classes={{
              circle: classes.circle,
            }}
            size={40}
            thickness={4}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {showProgress && (
              <Typography
                variant="caption"
                component="div"
                style={{ color: "white" }}
              >
                {progress && progress.toString().includes("/")
                  ? progress
                  : `${Math.round(progress)}%`}
              </Typography>
            )}
          </Box>
        </Box>
      </Backdrop>
    </div>
  );
}

export default Loader;
