export const SET_PROFILE = "SET_PROFILE";
export const SET_USERS = "SET_USERS";
export const SET_MERCHANTS = "SET_MERCHANTS";
export const SET_MERCHANT = "SET_MERCHANT";
export const SET_CART = "SET_CART";
export const SET_PROVINCES = "SET_PROVINCES";
export const SET_CITIES = "SET_CITIES";
export const SET_AREAS = "SET_AREAS";
export const SET_ADDRESSES = "SET_ADDRESSES";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER = "UPDATE_USER";
export const SET_SELECTED_MERCHANT = "SET_SELECTED_MERCHANT";
export const SET_SHIPPING_FEE = "SET_SHIPPING_FEE";

export const SET_INVOICES = "SET_INVOICES";
export const SET_QUOTATIONS = "SET_QUOTATIONS";
