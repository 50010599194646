import React, { useState, forwardRef, useEffect } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";

import Typography from "@material-ui/core/Typography";

import Slide from "@material-ui/core/Slide";
import { useDispatch } from "react-redux";
import { setAlertDialog } from "../../modules/App/actions";
import { withStyles, ThemeProvider, useTheme } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
  content: {
    padding: theme.spacing(4),
    color: theme.palette.text.primary,
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, align = "center", ...other } = props;
  const otherStyles = useStyles();
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography style={{ color: "white" }} variant="h6" align={align}>
        {children}
      </Typography>
      {onClose ? (
        <IconButton aria-label="close" className={otherStyles.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    display: "flex",
    flex: 1,
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AppAlertDialog({
  open = false,
  title = "",
  content = null,
  agreeText = "Yes",
  disagreeText = "No",
  onAgree,
  onDisagree,
  showActions = true,
  fullScreen = false,
  fullWidth = false,
  maxWidth = false,
  onClose,
  align = "center",
}) {
  // console.log("AppAlertDialog", showActions);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleClose = () => {
    dispatch(setAlertDialog(null));
  };

  const _onAgree = () => {
    if (onAgree) onAgree();
    handleClose();
  };

  const _onDisagree = () => {
    if (onDisagree) onDisagree();
    handleClose();
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      // className="custom-dialog"
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogTitle id="alert-dialog-slide-title" onClose={onClose} align={align}>
        {title}
      </DialogTitle>
      <DialogContent>
        <div id="alert-dialog-slide-description" className={classes.content}>
          {content}
        </div>
      </DialogContent>

      {showActions && (
        <DialogActions>
          <Button onClick={_onAgree} color="primary" variant="contained">
            {agreeText}
          </Button>
          <Button onClick={_onDisagree} color="secondary" variant="contained">
            {disagreeText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
}
