import { result } from "lodash";
import axios from "../../library/axios";
import { saveToStorage } from "../../library/utilities/Storage";
import * as authTypes from "../../modules/Auth/constants";
import { asyncActionError, asyncActionFinish, asyncActionStart, asyncActionSuccess } from "../App/actions";
import { updateUserDetails, updateUserDetailsAction } from "../Auth/actions";
import { getEstimates } from "../Estimate/action";
import {
  SET_MERCHANT,
  SET_MERCHANTS,
  SET_PROFILE,
  SET_USERS,
  SET_CART,
  SET_PROVINCES,
  SET_CITIES,
  SET_ADDRESSES,
  SET_AREAS,
  ADD_USER,
  UPDATE_USER,
  SET_SELECTED_MERCHANT,
  SET_INVOICES,
  SET_QUOTATIONS,
} from "./constants";

// api
export const getUsers = (limit = 200, page = 0) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      let result = await axios.get("users", {
        params: {
          limit,
          page,
        },
      });

      dispatch(setUsers(result.data));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const updateQuotation = (id, form) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const { data } = await axios.put(`estimates/${id}`, form);
      console.log(data);
      dispatch(getEstimates());
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const disableAccount = (id) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const response = await axios.put(`users/disableaccount/${id}`);
      console.log(response);
      dispatch(asyncActionSuccess({ message: "Account Disabled" }));
      return response?.data;
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const reenableAccount = (id) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const response = await axios.put(`users/reenableaccount/${id}`);
      console.log(response);
      dispatch(asyncActionSuccess({ message: "Account Re-enabled" }));
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const disableProduct = (id) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const response = await axios.put(`products/disable/${id}`);
      console.log(response);
      dispatch(asyncActionSuccess({ message: "Product Disabled" }));
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const enableProduct = (id) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const response = await axios.put(`products/enable/${id}`);
      console.log(response);
      dispatch(asyncActionSuccess({ message: "Product Enabled" }));
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const addUserAdmin = (data) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const response = await axios.post(`users/delegated`, data);
      console.log(response.data);
      dispatch(addAdmin(response.data.user));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const updateUser = (id, data) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const response = await axios.patch(`users/${id}`, data);
      console.log(response.data);
      // dispatch(addAdmin(response.data.user))
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const getMerchants = (limit = 20, page = 0) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      let result = await axios.get("merchants", {
        params: {
          limit,
          page,
        },
      });

      dispatch(setMerchants(result.data));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const patchMerchant = (_id, data) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      let result = await axios.patch("merchants/" + _id, data);
      console.log("patchMerchant", result.data);
      dispatch(setMerchant(result.data));
      dispatch(updateUserDetailsAction({ merchant: result.data }));
      //saveToStorage(authTypes.ACCESS_TOKEN, result.data.accessToken);
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const getMerchant = (id) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      let result = await axios.get("merchants/" + id);
      console.log("getMerchant", result.data);
      dispatch(setMerchant(result.data));
      dispatch(asyncActionFinish());
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

// export const addToCart = (product) => {
//   return async (dispatch) => {
//     const existingCart = localStorage.getItem("cart");
//     const findExistingProduct = existingCart
//       ? JSON.parse(existingCart).find((p) => p._id === product._id)
//       : null;
//     if (findExistingProduct) {
//       const newCart = JSON.parse(existingCart).map((item) => {
//         return {
//           ...item,
//           cartCount: item.cartCount + product.cartCount,
//         };
//       });
//       dispatch(setCart(newCart));
//       return localStorage.setItem("cart", JSON.stringify(newCart));
//     }

//     if (existingCart) {
//       const newCart = JSON.stringify([...JSON.parse(existingCart), product]);
//       dispatch(setCart(JSON.parse(newCart)));
//       localStorage.setItem("cart", newCart);
//     } else {
//       const newCart = JSON.stringify([product]);
//       dispatch(setCart(JSON.parse(newCart)));
//       localStorage.setItem("cart", newCart);
//     }
//   };
// };
export const getCart = () => {
  return async (dispatch) => {
    //dispatch(asyncActionStart());
    try {
      const result = await axios.get("cart");

      dispatch(setCart(result.data));
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError(error));
    }
  };
};

export const addToCart = (data, callback) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const result = await axios.post("cart/", data);

      dispatch(setCart(result.data));
      dispatch(asyncActionFinish());
      if (callback) callback();
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError(error));
    }
  };
};

export const updateCart = (data, callback) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      let result = await axios.patch("cart/", data);

      dispatch(setCart(result.data));
      dispatch(asyncActionFinish());
      if (callback) callback();
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const removeFromCart = (product, callback) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      let result = await axios.delete("cart/" + product);
      dispatch(setCart(result.data));
      dispatch(asyncActionFinish());
      if (callback) callback();
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export const removeAll = (callback) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      let result = await axios.delete("cart/removeAll");

      dispatch(setCart(result.data));
      dispatch(asyncActionFinish());
      if (callback) callback();
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

export function getProvinces() {
  return async (dispatch) => {
    try {
      const response = await axios.get("/users/ph-locations/provinces");
      dispatch(setProvinces(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getCitiesByProvince(provinceId) {
  return async (dispatch) => {
    try {
      dispatch(setCities());
      const response = await axios.get("/users/ph-locations/cities/" + provinceId);

      dispatch(setCities(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function getAreasByProvinceAndCity(province, city) {
  return async (dispatch) => {
    try {
      dispatch(setAreas());
      const response = await axios.get(`/users/ph-locations/areas/${province}/${city}`);

      dispatch(setAreas(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}

export function createAddress(request) {
  return async (dispatch) => {
    try {
      const response = await axios.post("/users/address-book/create", request);
      dispatch(getUserAddresses());

      return response?.data;
    } catch (error) {
      console.log(error, "ERROR");
    }
  };
}

export function patchAddress(id, values) {
  return async (dispatch) => {
    try {
      const response = await axios.patch("/users/address-book/" + id, values);
      dispatch(getUserAddresses());

      return response?.data;
    } catch (error) {
      console.log(error, "ERROR");
    }
  };
}

export function getUserAddresses() {
  return async (dispatch) => {
    try {
      const response = await axios.get("/users/address-book/list");
      dispatch(setAddresses(response.data));
    } catch (error) {
      console.log(error);
    }
  };
}
export function getMyInvoices(userId) {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/invoices/byuser/${userId}`);
      console.log(data);
      dispatch(setInvoices(data.invoices.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))));
    } catch (error) {
      console.log(error);
    }
  };
}
export function getMyQuotations(userId) {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(`/estimates/byuser/${userId}`);
      console.log(data);
      dispatch(setQuotations(data.quotations.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))));
    } catch (error) {
      console.log(error);
    }
  };
}
export function deleteUserAddress(id) {
  return async (dispatch) => {
    try {
      const response = await axios.delete("/users/address-book/delete/" + id);
      console.log(response, "RESPONSE");
      return response;
    } catch (error) {
      console.log(error);
    }
  };
}

export function getShippingFee(logistics, merchant, to, weight = 0.2) {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const response = await axios.get("/users/shipping-fee/calculate", {
        params: {
          logistics,
          merchant,
          to,
          weight,
        },
      });
      console.log("GETSF", response);
      dispatch(asyncActionFinish());
      return response.data;
    } catch (error) {
      console.log(error);
      // dispatch(asyncActionError(error));
    }
  };
}

export function getShipmatesFee(qid, weight, logistics, merchant, userAddress, cart, total, mdealsfee, payment, courier = "None", orderNo = "None") {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const response = await axios.get("/users/shipmates-fee/api", {
        params: {
          qid,
          weight,
          logistics,
          merchant,
          userAddress,
          cart,
          total,
          mdealsfee,
          payment,
          courier,
          orderNo,
        },
      });
      dispatch(asyncActionFinish());
      return response.data.shipmateFee;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError(error));
    }
  };
}

export function getServiceType(data, weight, logistics) {
  try {
    console.log("GetService", weight, "kg", logistics);
    let result;
    if (logistics === "borzo") {
      if (weight <= 20) {
        result = data.find((shipper) => shipper.courier_service_name === "Motorcycle (Insulated Bag, 20kg)" && shipper.courier_service === "borzo-motorcycle");
        return result;
      } else if (weight > 20 && weight <= 200) {
        result = data.find((shipper) => shipper.courier_service_name === "Car (200kg)");
        return result;
      }
    } else if (logistics === "grab") {
      if (weight <= 20) {
        result = data.find((shipper) => shipper.courier_service_name === "Motorcycle (Insulated Bag, 20kg)" && shipper.courier_service === "grab-motorcycle");
        return result;
      }
    } else if (logistics === "lalamove") {
      if (weight <= 20) {
        result = data.find((shipper) => shipper.courier_service_name === "Motorcycle (Insulated Bag, 20kg)");
        console.log("Lalamove", result);
        return result;
      } else if (weight > 20 && weight <= 200) {
        result = data.find((shipper) => shipper.courier_service_name === "Sedan (200kg)");
        return result;
      } else if (weight > 200 && weight <= 300) {
        result = data.find((shipper) => shipper.courier_service_name === "Car (300kg)");
      } else if (weight > 300 && weight <= 600) {
        result = data.find((shipper) => shipper.courier_service_name === "Van (600kg)");
        return result;
      } else if (weight > 600 && weight <= 1000) {
        result = data.find((shipper) => shipper.courier_service_name === "Van (1000kg)");
        return result;
      } else if (weight > 1000 && weight <= 2000) {
        result = data.find((shipper) => shipper.courier_service_name === "Truck (2000kg)");
        return result;
      }
    } else {
      return 0;
    }
  } catch (error) {
    if (!data) {
      console.log("No ondemand courier found");
    } else {
      console.log("Error getting shipmates data");
    }
  }
}

// redux

export function setInvoices(payload) {
  try {
    return {
      type: SET_INVOICES,
      payload,
    };
  } catch (error) {
    console.log("The error", error);
  }
}
export function setQuotations(payload) {
  return {
    type: SET_QUOTATIONS,
    payload,
  };
}
export function setProfile(payload) {
  return {
    type: SET_PROFILE,
    payload,
  };
}

export function setUsers(payload) {
  return {
    type: SET_USERS,
    payload,
  };
}

export function setMerchants(payload) {
  return {
    type: SET_MERCHANTS,
    payload,
  };
}

export function setMerchant(payload) {
  return {
    type: SET_MERCHANT,
    payload,
  };
}

export function setCart(payload) {
  return {
    type: SET_CART,
    payload,
  };
}

export function setProvinces(payload = []) {
  return {
    type: SET_PROVINCES,
    payload,
  };
}

export function setCities(payload = []) {
  return {
    type: SET_CITIES,
    payload,
  };
}

export function setAreas(payload = []) {
  return {
    type: SET_AREAS,
    payload,
  };
}

export function setAddresses(payload = []) {
  return {
    type: SET_ADDRESSES,
    payload,
  };
}

export function addAdmin(payload) {
  return {
    type: ADD_USER,
    payload,
  };
}

export function updateAdmin(payload) {
  return {
    type: UPDATE_USER,
    payload,
  };
}

export function setSelectedMerchant(payload) {
  return {
    type: SET_SELECTED_MERCHANT,
    payload,
  };
}
