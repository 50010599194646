import {
  ASYNC_ACTION_ERROR,
  ASYNC_ACTION_FINISH,
  ASYNC_ACTION_START,
  APP_LOADED,
  SET_SHOW_SIDEBAR,
  SET_SHOW_PROGRESS,
  SET_PROGRESS,
  SET_PREVIEW,
  TOGGLE_SHOW_LIVE_SIDEBAR,
  SET_ALERT_DIALOG,
  TOGGLE_NAVIGATION_LAYOUT,
  SET_APP_SETTINGS,
  SET_BANNERS,
  SET_DOCUMENTS,
  SET_MERCHANT_CHATS,
  SET_SELECTED_MERCHANT_CHAT,
  SET_SELECTED_CHAT,
  OPEN_CHATBOX,
  CLOSE_CHATBOX,
  SET_ADMIN_VOUCHERS,
  LOADING_START,
  LOADING_FINISH,
} from "./constants";
import { toast } from "react-toastify";
import axios from "../../library/axios";
import { errors } from "../../library/common/constants";
import { doc, getFirestore, setDoc, updateDoc } from "firebase/firestore";
import { initializeApp } from "firebase/app";
import { config } from "../../library/common/firebase";

// api

const app = initializeApp(config());
const db = getFirestore(app);

export const createPayment = (values) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      console.log("before bux =>", values);
      const body = {
        ...values,
        order: values.order,
        email: values.email,
        mobile: values.contact,
        name: values.name,
        channel: values.channelcode,
      };
      const { data } = await axios.post("/bux", body);
      console.log("bux response =>", data);
      if (data.payment_url) {
        window.open(data.payment_url, "_blank");
      }
      dispatch(asyncActionFinish());
      return data;
    } catch (error) {
      dispatch(asyncActionFinish());
      console.log(error);
    }
  };
};
export const claimVoucher = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("/uservouchers", values);
      console.log(data);
      if (!data.success) {
        return dispatch(asyncActionError(data.message));
      }
      dispatch(asyncActionSuccess({ message: "Voucher Claimed Successfully!" }));
    } catch (error) {
      console.log(error);
    }
  };
};

export const createVoucher = (values) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post("/vouchers", values);
      console.log(data);
      dispatch(getAdminVouchers());
    } catch (error) {
      console.log(error);
    }
  };
};
export const getAdminVouchers = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get("/vouchers/adminvouchers");
      console.log(data);
      dispatch(setAdminVouchers(data.vouchers));
    } catch (error) {
      console.log(error);
    }
  };
};

export const removeVoucher = (id) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      const { data } = await axios.delete(`/vouchers/${id}`);
      console.log(data);
      if (data) {
        dispatch(asyncActionSuccess({ message: "Successfully deleted" }));
        return data;
      }
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionFinish());
    }
  };
};

export const updateAdminVoucher = (id, value) => {
  return async (dispatch) => {
    try {
      dispatch(asyncActionStart());
      console.log("value =>", value);
      const { data } = await axios.put(`/vouchers/${id}`, value);
      dispatch(asyncActionFinish());
      return data;
    } catch (error) {
      dispatch(asyncActionFinish());
      console.log(error);
    }
  };
};

export const getSuggestions = () => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const { data } = await axios.get("suggestions");
      dispatch(asyncActionFinish());
      return data.suggestions;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError("An error occurred"));
    }
  };
};
export const getAppSettings = (callback) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const result = await axios.get("app-settings/active");
      dispatch(setAppSettings(result.data));
      console.log("getAppSettings", result.data);
      if (callback) callback();
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError("Failed to retrieve app settings"));
    }
  };
};

export const getMerchantChats = () => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const { data } = await axios.get(`merchantchats`);
      console.log(data);
      dispatch(setMerchantChats(data.conversations.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))));
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError("Failed to get messages"));
    }
  };
};

export const getConversationWithMerchant = (id) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const { data } = await axios.get(`merchantchats/withmerchant/${id}`);
      console.log(data);
      dispatch(setSelectedMerchantChat(data.messages));
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError("Failed to get messages"));
    }
  };
};
export const getMyConversationWithAdmin = (id) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const { data } = await axios.get(`merchantchats/bymerchant/${id}`);
      console.log(data);
      dispatch(setSelectedChat(data.conversation));
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError("Failed to get messages"));
    }
  };
};
export const markAsRead = (id) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const result = await axios.put(`merchantchats/markasread/${id}`);
      // dispatch(setBanners(result.data.banners));
      console.log("markasread", result.data);
      dispatch(getMerchantChats());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError("Failed to send message"));
    }
  };
};
export const sendMerchantChat = (merchantid, data, newChat = false) => {
  return async (dispatch) => {
    // dispatch(asyncActionStart());
    try {
      const convRef = doc(db, "adminmessages", merchantid);
      const adminRef = doc(db, "adminmessages", merchantid, "messages", Date.now().toString());
      console.log("data", data);

      if (data.merchantid) {
        await setDoc(convRef, {
          ...data,
          storeName: data.storeName,
          timestamp: Date.now(),
          lastMessage: data.message,
        });
      }

      await updateDoc(convRef, {
        timestamp: Date.now(),
        ...data,
      });

      await setDoc(adminRef, {
        ...data,
        lastMessage: data.message,
        timestamp: Date.now(),
      });

      if (newChat) {
        axios.post(`/messages/notifications`, {
          chatAdmin: true,
        });
      }

      // const result = await axios.post(`merchantchats/${merchantid}`, data);
      // dispatch(setBanners(result.data.banners));

      // dispatch(getConversationWithMerchant(merchantid));
      // dispatch(getMerchantChats());
      // dispatch(asyncActionSuccess({ message: "Message Sent" }));
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError("Failed to send message"));
    }
  };
};

export const getDocuments = () => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const { data } = await axios.get(`documents`);
      console.log(data);
      dispatch(setDocuments(data.docs));
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError("Failed to get documents"));
    }
  };
};

export const createDoc = (data) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const result = await axios.post("documents", data);
      // dispatch(setBanners(result.data.banners));
      console.log("document", result.data);
      dispatch(getDocuments());
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError("Failed to create document"));
    }
  };
};

export const removeDoc = (id) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const result = await axios.delete(`documents/${id}`);
      // dispatch(setBanners(result.data.banners));
      console.log("document", result.data);
      dispatch(getDocuments());
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError("Failed to remove document"));
    }
  };
};

export const createBanner = (form) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const result = await axios.post("banners", form);
      // dispatch(setBanners(result.data.banners));
      console.log("banners", result.data);
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError("Failed to create banner"));
    }
  };
};
export const removeBanner = (id) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const result = await axios.delete(`banners/${id}`);
      // dispatch(setBanners(result.data.banners));
      console.log("banners", result.data);
      dispatch(getBanners());
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError("Failed to remove banner"));
    }
  };
};
export const getBanners = () => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      const result = await axios.get("banners");
      dispatch(setBanners(result.data.banners));
      console.log("banners", result.data.banners);
      dispatch(asyncActionFinish());
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError("Failed to retrieve banners"));
    }
  };
};

export const patchAppSettings = (data, callback) => {
  return async (dispatch) => {
    dispatch(asyncActionStart());
    try {
      let result = await axios.patch("app-settings/active", data);
      console.log(data);
      dispatch(setAppSettings(result.data));
      dispatch(asyncActionSuccess({ message: "Fees updated." }));
      if (callback) callback();
    } catch (error) {
      dispatch(asyncActionError(error));
    }
  };
};

// redux

export function loadingStart() {
  return {
    type: LOADING_START,
  };
}
export function loadingFinish() {
  return {
    type: LOADING_FINISH,
  };
}
export function asyncActionStart() {
  return {
    type: ASYNC_ACTION_START,
  };
}

export function asyncActionFinish() {
  return {
    type: ASYNC_ACTION_FINISH,
  };
}

export function asyncActionSuccess(payload) {
  toast.success(payload.message, { autoClose: 6000 });
  return {
    type: ASYNC_ACTION_FINISH,
  };
}

export function asyncActionError(payload) {
  try {
    console.log(payload?.error);
    console.log(payload?.error || payload?.message || payload);
    if (payload === "Network Error") {
      toast.error(errors.NETWORK_ERROR);
    } else {
      toast.error(payload?.response?.data?.message || payload?.response?.data?.error || payload?.error || payload?.message || payload);
    }
  } catch (error) {
    console.log(error);
  }

  return {
    type: ASYNC_ACTION_ERROR,
    payload,
  };
}

export function setAppLoaded(payload = true) {
  return {
    type: APP_LOADED,
    payload,
  };
}

export function setShowSidebar(payload = true) {
  return {
    type: SET_SHOW_SIDEBAR,
    payload,
  };
}

export function setShowProgress(payload = true) {
  return {
    type: SET_SHOW_PROGRESS,
    payload,
  };
}

export function setProgress(payload = 0) {
  return {
    type: SET_PROGRESS,
    payload,
  };
}

export function setPreview(payload = false) {
  return {
    type: SET_PREVIEW,
    payload,
  };
}

export function toggleShowLiveSidebar() {
  return {
    type: TOGGLE_SHOW_LIVE_SIDEBAR,
  };
}

export function setAlertDialog(
  payload = {
    open: false,
    title: "",
    content: null,
    agreeText: "Yes",
    disagreeText: "No",
    onAgree: () => {},
    onDisagree: () => {},
    showActions: true,
    fullScreen: false,
    fullWidth: false,
    maxWidth: false,
    onClose: null,
    align: "center",
  }
) {
  return {
    type: SET_ALERT_DIALOG,
    payload,
  };
}

export function toggleNavigationLayout() {
  return {
    type: TOGGLE_NAVIGATION_LAYOUT,
  };
}

export function setAppSettings(payload = {}) {
  return {
    type: SET_APP_SETTINGS,
    payload,
  };
}

export function setBanners(payload) {
  return {
    type: SET_BANNERS,
    payload,
  };
}

export function setDocuments(payload) {
  return {
    type: SET_DOCUMENTS,
    payload,
  };
}

export function setMerchantChats(payload) {
  return {
    type: SET_MERCHANT_CHATS,
    payload,
  };
}

export function setSelectedMerchantChat(payload) {
  return {
    type: SET_SELECTED_MERCHANT_CHAT,
    payload,
  };
}

export function setSelectedChat(payload) {
  return {
    type: SET_SELECTED_CHAT,
    payload,
  };
}

export function openChat() {
  return {
    type: OPEN_CHATBOX,
  };
}

export function closeChat() {
  return {
    type: CLOSE_CHATBOX,
  };
}

export function setAdminVouchers(payload) {
  return {
    type: SET_ADMIN_VOUCHERS,
    payload,
  };
}
