export const SET_SITE = "SET_SITE";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const SET_VIEWS = "SET_VIEWS";
export const SET_SALES_AND_ORDERS = "SET_SALES_AND_ORDERS";
export const SET_SELECTED_VIEWS = "SET_SELECTED_VIEWS";
export const SET_PRODUCT_RANKINGS = "SET_PRODUCT_RANKINGS";
export const SET_BANK_ACCOUNTS = "SET_BANK_ACCOUNTS";
export const SET_STORE_VOUCHERS = "SET_STORE_VOUCHERS";
export const SET_STORE_VALID_VOUCHERS = "SET_STORE_VALID_VOUCHERS";
export const SET_MERCHANT_PRODUCTS = "SET_MERCHANT_PRODUCTS";
export const SET_MERCHANT_DISBURSEMENT = "SET_MERCHANT_DISBURSEMENT";
