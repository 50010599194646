import {
  SET_MESSAGES,
  SET_MESSAGES_WITH_EXISTING,
  SET_HAS_CHATS_WITH,
  SET_OPEN_CHAT,
} from "./constants";
import {
  asyncActionError,
  asyncActionFinish,
  asyncActionStart,
  setAppLoaded,
} from "../App/actions";

import axios from "../../library/axios";

export const getMerchantDetails = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/merchants/" + id);

      return response;
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const sendMessage = (msg) => {
  return async (dispatch) => {
    try {
      const response = await axios.post("/users/send-message", msg);

      return response;
    } catch (error) {
      console.log(error, "error sending msg");
    }
  };
};

export const getMessagesWith = (userId) => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/users/get-messages-with/" + userId);
      console.log(response, "response getting chats");
      dispatch(setMessages(response.data || []));
    } catch (error) {
      console.log(error);
    }
  };
};

export const getHasMessagesWith = () => {
  return async (dispatch) => {
    try {
      console.log("HIT!");
      const response = await axios.get("/users/message/list/with");
      console.log(response, "response getting chat list");
      dispatch(setHasChatsWith(response.data));
      return response;
    } catch (error) {
      console.log(error, "error getting");
    }
  };
};

export const setOpenChat = (payload) => {
  return {
    type: SET_OPEN_CHAT,
    payload,
  };
};

export const setHasChatsWith = (payload) => {
  return {
    type: SET_HAS_CHATS_WITH,
    payload,
  };
};
export const setMessages = (payload) => {
  return {
    type: SET_MESSAGES,
    payload,
  };
};
export const setMessagesWithExisting = (payload) => {
  return {
    type: SET_MESSAGES_WITH_EXISTING,
    payload,
  };
};
