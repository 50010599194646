import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { SIGN_OUT_USER } from "../../modules/Auth/constants";

import app from "../../modules/App/reducer";
import auth from "../../modules/Auth/reducer";
import user from "../../modules/User/reducer";
import merchant from "../../modules/Merchant/reducer";
import product from "../../modules/Product/reducer";
import inventory from "../../modules/Inventory/reducer";
import home from "../../components/Home/reducer";
import chat from "../../modules/Chat/reducer";
import order from "../../modules/Order/reducer";
import affiliate from "../../modules/Affiliate/reducer";
import sellerPolicy from "../../modules/SellerPolicy/reducer";
import alibaba from "../../modules/AlibabaProducts/reducer"
import estimate from "../../modules/Estimate/reducer"
import conversations from "../../modules/Messages/reducer"
import invoices from "../../modules/Invoices/reducer"
import deals from "../../modules/Deals/reducer"

const mainReducer = (state, action, history) => {
  const allReducers = combineReducers({
    router: connectRouter(history),
    app,
    auth,
    user,
    merchant,
    product,
    inventory,
    home,
    chat,
    order,
    affiliate,
    sellerPolicy,
    alibaba, 
    estimate, 
    conversations,
    invoices,
    deals
  });
  if (action.type === SIGN_OUT_USER) {
    state = {
      app: state.app,
    };
  }
  return allReducers(state, action);
};

export default mainReducer;
