export const SET_POINTS = "SET_POINTS";
export const HANDLE_POINTS_DECREASE = "HANDLE_POINTS_DECREASE";
export const SET_MY_WITHDRAWAL_HISTORY = "SET_MY_WITHDRAWAL_HISTORY";
export const HANDLE_STACK_WITHDRAWAL = "HANDLE_STACK_WITHDRAWAL";
export const SET_USERS_WITHDRAWALS = "SET_USERS_WITHDRAWALS";
export const SET_AFFILIATE_MEMBERSHIP_ORDER = "SET_AFFILIATE_MEMBERSHIP_ORDER";
export const NEW_AFFILIATE_MEMBER = "NEW_AFFILIATE_MEMBER";
export const SET_TRANSACTION_DETAILS = "SET_TRANSACTION_DETAILS";
export const SET_ORDERS_BY_AFFILIATELINKS = "SET_ORDERS_BY_AFFILIATELINKS";
export const SET_POINTHISTORY = "SET_POINTHISTORY";
export const SET_USER_STATS = "SET_USER_STATS";
