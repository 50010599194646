import axios from "../../library/axios";
import { objToFormData } from "../../library/utilities";
import { asyncActionError, asyncActionFinish } from "../App/actions";

// api
export const uploadTempMultiple = (tempFiles) => {
  return async (dispatch) => {
    try {
      const result = await axios.post(
        "upload/multiple",
        objToFormData({
          tempFiles,
        }),
        {}
      );
      console.log("uploadTempMultiple", result);
      dispatch(asyncActionFinish());
      return result.data;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError(error));
    }
  };
};

export const uploadTempSingle = (tempFile) => {
  return async (dispatch) => {
    try {
      const result = await axios.post(
        "upload/single",
        objToFormData({
          tempFile,
        }),
        {}
      );
      console.log(result);
      dispatch(asyncActionFinish());
      return result.data;
    } catch (error) {
      console.log(error);
      dispatch(asyncActionError(error));
    }
  };
};
