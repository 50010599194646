import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, FormControl, InputLabel, Select, MenuItem, Typography } from "@mui/material";
import { differenceInDays, differenceInSeconds } from "date-fns";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { signOut, updateUserDetails, updateUserDetailsX } from "../../modules/Auth/actions";
import * as Yup from "yup";

function AuthChecker() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  if (user) {
    const days = differenceInDays(new Date(), new Date(user?.iat * 1000));
    if (days > 0) {
      dispatch(signOut());
    }
  }

  const [openDialog, setOpenDialog] = useState(Boolean(!user?.mobile));
  return <React.Fragment>{user && !user.mobile && !user.isAdmin && <PersonalInfo user={user} open={openDialog} onClose={() => setOpenDialog(false)} />}</React.Fragment>;
}

const PersonalInfo = ({ user, open, onClose }) => {
  const [profile, setProfile] = useState(user);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: user,
    validationSchema: Yup.object({
      mobile: Yup.string().required(),
      sex: Yup.string(),
      occupation: Yup.string(),
      age: Yup.number(),
      business: Yup.string(),
    }),
    onSubmit: async (values) => {
      console.log(values);
      console.log({ ...user, ...values });
      const data = await dispatch(updateUserDetails(user._id, { ...user, ...values }));
      console.log(data);
      onClose();
    },
  });
  return (
    <Dialog open={open} maxWidth="sm" fullWidth disableEscapeKeyDown component="form" onSubmit={formik.handleSubmit} onClose={onClose}>
      <DialogTitle style={{ fontFamily: "Poppins" }}>Your Personal Information</DialogTitle>
      <DialogContent>
        <Typography style={{ fontFamily: "Poppins", fontSize: 12, marginBottom: 10 }} color="GrayText">
          Please provide us with the following information for us to be able to provide you with the best deals.
        </Typography>
        <TextField label="First Name" value={profile.firstName} InputProps={{ readOnly: true }} fullWidth size="small" style={{ marginTop: 10 }} />
        <TextField label="Last Name" value={profile.lastName} InputProps={{ readOnly: true }} fullWidth size="small" style={{ marginTop: 10 }} />
        <TextField label="Email" value={profile.email} InputProps={{ readOnly: true }} fullWidth size="small" style={{ marginTop: 10 }} />
        <TextField label="Mobile" style={{ marginTop: 10 }} value={formik.values?.mobile} name="mobile" onChange={formik.handleChange} fullWidth size="small" required />
        <TextField label="Age" style={{ marginTop: 10 }} value={formik.values?.age} name="age" onChange={formik.handleChange} fullWidth size="small" type="number" />
        <FormControl fullWidth size="small" style={{ marginTop: 10 }}>
          <InputLabel id="demo-simple-select-label">Sex</InputLabel>
          <Select labelId="demo-simple-select-label" id="demo-simple-select" value={formik.values?.sex} label="Sex" name="sex" onChange={formik.handleChange}>
            <MenuItem value={"Male"}>Male</MenuItem>
            <MenuItem value={"Female"}>Female</MenuItem>
            <MenuItem value={"Others"}>Others</MenuItem>
          </Select>
        </FormControl>
        <TextField label="Business" style={{ marginTop: 10 }} value={formik.values?.business} name="business" onChange={formik.handleChange} fullWidth size="small" />
        <TextField
          label="Occupation"
          style={{ marginTop: 10 }}
          value={formik.values?.occupation}
          name="occupation"
          onChange={formik.handleChange}
          fullWidth
          size="small"
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" size="small" type="submit">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AuthChecker;
