// export const passwordRegex = /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;
export const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>])[a-zA-Z\d!@#$%^&*()\-_=+{};:,<.>]{8,}$/;
export const passwordMessage = "Password must contain at least 8 characters, one uppercase, one number and one special character";

export const featuredCategories = [
  {
    name: "Apparel",
    mainImage: require("../../../resources/images/international-products/apparel.jpg").default,
    products: [
      {
        link: "https://www.alibaba.com/product-detail/Men-s-Short-Sleeve-Quick-Dry_1600134389934.html",
        src: "https://s.alicdn.com/@sc04/kf/H1bf2b28ac76a456297ae985137c51c0c9.jpg_220x220.jpg",
        title: "Men's Short Sleeve Quick Dry Army Polo Shirt",
        price: "US$2.95 - US$4.00",
        minimum_order: "2 Pieces",
      },
      {
        link: "https://www.alibaba.com/product-detail/Korean-Trend-Knitted-Black-Plus-Size_1600206235302.html",
        src: "https://s.alicdn.com/@sc04/kf/H3b61306d55de4888bb726f411ae9e18eX.jpg_220x220.jpg",
        title: "Korean Trend  Knitted Black Plus Size Fashion Striped Men Crew Turtle",
        price: "US$5.00 - US$5.67",
        minimum_order: "2 Pieces",
      },
      {
        link: "https://www.alibaba.com/product-detail/Summer-fashionable-Ladies-Cotton-Dress-bale_1600315194065.html",
        src: "https://s.alicdn.com/@sc04/kf/Hb2b78db00b4a4bdd9cce9abf2f6e9336T.jpg_220x220.jpg",
        title: "Summer fashionable Ladies Cotton Dress bale used clothes in China adult clothes",
        price: "US$0.99 - US$1.50",
        minimum_order: "400 Kilograms",
      },
      {
        link: "https://www.alibaba.com/product-detail/Summer-fashionable-LADIES-COTTON-DRESS-bale_1600326783813.html",
        src: "https://s.alicdn.com/@sc04/kf/Hfe9f72430b2640d3b932b7247b103e7fn.jpg_220x220.jpg",
        title: "Summer fashionable LADIES COTTON DRESS bale used clothes in China adult clothes",
        price: "US$0.40 - US$0.80",
        minimum_order: "100 Kilograms",
      },
    ],
  },
  {
    name: "Electronics",
    mainImage: require("../../../resources/images/international-products/electronics.jpg").default,
    products: [
      {
        link: "https://www.alibaba.com/product-detail/TWS-PRO-4-Pro5-Wireless-Earbuds_1600316596133.html",
        src: "https://s.alicdn.com/@sc04/kf/Hd876e040798e41ecae1d9d7b538d36f3x.jpg_220x220.jpg",
        title: "TWS PRO 4 Pro5 Wireless Earbuds Pro4 BT 5.0 Headphones Wireless Charging Case for ios and Android Smart phones",
        price: "US$0.88",
        minimum_order: "1 Set",
      },
      {
        link: "https://www.alibaba.com/product-detail/2021-New-Smart-Band-Fitness-Tracker_1600232319996.html",
        src: "https://s.alicdn.com/@sc04/kf/Hfdb38af816234fd08196cbcec4432c5eK.jpg_220x220.jpg",
        title: "2021 New Smart Band Fitness Tracker Heart Rate Blood Pressure Monitor Color Screen M6 Smart Watch Bracelet IP67 Waterproof",
        price: "US$1.79 - US$2.59",
        minimum_order: "1 Piece",
      },
      {
        link: "https://www.alibaba.com/product-detail/540-Magnetic-Charging-Cable-360-180_1600163177488.html",
        src: "https://s.alicdn.com/@sc04/kf/H51cf870bd98040049de0818620894bd4g.jpg_220x220.jpg",
        title: "2540 Magnetic Charging Cable 360 & 180 Rotation 3 in 1 Magnetic Phone USB Charger Cable Compatible with Micro USB, Type-C, iOS 1M",
        price: "US$0.11 - US$1.53",
        minimum_order: "1 Piece",
      },
      {
        link: "https://www.alibaba.com/product-detail/sports-camera-go-pro-her-9_1600224774675.html",
        src: "https://s.alicdn.com/@sc04/kf/H7e7521d69ad3482c9a64b425158a4288I.jpg_220x220.jpg",
        title: "sports camera go pro her 9 motorcycle helmet slow motion Aksiyon kamerasi HD 1080p mini camcorders Action Camera video full hd",
        price: "US$3.90 - US$6.50",
        minimum_order: "1 Piece",
      },
    ],
  },
  {
    name: "Health and Beauty",
    mainImage: require("../../../resources/images/international-products/health2.jpg").default,
    products: [
      {
        link: "https://www.alibaba.com/product-detail/Authentic-Dove-Deodorant-Spray-Available_1700007566403.html",
        src: "https://s.alicdn.com/@sc04/kf/U272a8971d1c74be989a4145f442cc963b.jpeg_220x220.jpeg",
        title: "Authentic Dove Deodorant Spray Available",
        price: "US$0.02 - US$0.04",
        minimum_order: "100 units",
      },
      {
        link: "https://www.alibaba.com/product-detail/7-in-1-bag-guangzhou-cosmetic_62019183856.html",
        src: "https://s.alicdn.com/@sc04/kf/Uc6036c193cc94700afae1d8be30e8297l.jpg_220x220.jpg",
        title: "7 in 1 bag guangzhou cosmetic tools new products 2020 powder puff makeup sponge blender",
        price: "US$0.32 - US$0.50",
        minimum_order: "1 bag",
      },
      {
        link: "https://www.alibaba.com/product-detail/High-Quality-UV-Nail-LED-Lamp_1600129572495.html",
        src: "https://s.alicdn.com/@sc04/kf/H7dcc214b68234a16b2744471224a7e4eC.jpg_220x220.jpg",
        title: "High Quality UV Nail LED Lamp Polish Dryer Gel Acrylic Curing Light Spa Professional Kits High Quality Nail LED Lamp",
        price: "US$2.10 - US$3.00",
        minimum_order: "1 piece",
      },
      {
        link: "https://www.alibaba.com/product-detail/Wholesale-Korean-Cosmetic-Beauty-Face-Moisturizing_1600139048418.html",
        src: "https://s.alicdn.com/@sc04/kf/Hd2f65be8e2494701b6c85af0d5acc41cB.png_220x220.png",
        title: "Wholesale Korean Cosmetic Beauty Face Moisturizing Skin Care Facial Mask",
        price: "US$0.04 - US$0.07",
        minimum_order: "100 pieces",
      },
    ],
  },
];

export const alibabaCategories = [
  {
    name: "Agriculture & Food",
    subcategories: [
      {
        name: "Agriculture",
        subcategories: [
          {
            name: "Agricultural Waste",
            originalLink: "https://www.alibaba.com/catalog/agricultural-waste_cid138",
          },
          {
            name: "Animal Products",
            originalLink: "http://www.alibaba.com/Animal-Products_pid100003006",
          },
          {
            name: "Beans",
            originalLink: "http://www.alibaba.com/Beans_pid105",
          },
          {
            name: "Cocoa Beans",
            originalLink: "http://www.alibaba.com/Cocoa-Beans_pid10502",
          },
          {
            name: "Coffee Beans",
            originalLink: "http://www.alibaba.com/Coffee-Beans_pid100009043",
          },
          {
            name: "Farm Machinery & Equipment",
            originalLink: "http://www.alibaba.com/Farm-Machinery-Equipment_pid100003758",
          },
          {
            name: "Feed",
            originalLink: "http://www.alibaba.com/Feed_pid100002650",
          },
        ],
      },
      {
        name: "Food and Beverage",
        subcategories: [
          {
            name: "Alcoholic Beverage",
            originalLink: "http://www.alibaba.com/Alcoholic-Beverage_pid204",
          },
          {
            name: "Baby Food",
            originalLink: "http://www.alibaba.com/Baby-Food_pid100008699",
          },
          {
            name: "Baked Goods",
            originalLink: "http://www.alibaba.com/Baked-Goods_pid218",
          },
          {
            name: "Bean Products",
            originalLink: "http://www.alibaba.com/Bean-Products_pid207",
          },
          {
            name: "Canned Food",
            originalLink: "http://www.alibaba.com/Canned-Food_pid214",
          },
          {
            name: "Confectionary",
            originalLink: "http://www.alibaba.com/Confectionery_pid236",
          },
        ],
      },
    ],
  },
  {
    name: "Apparel, Textiles and Accessories",
    subcategories: [
      {
        name: "Apparel",
        subcategories: [
          {
            name: "Apparel Design Services",
            originalLink: "http://www.alibaba.com/Apparel-Design-Services_pid280503?spm=a2700.9161164.2.2.1a514e02P9QMcs",
          },
          {
            name: "Apparel Processing Services",
            originalLink: "http://www.alibaba.com/Apparel-Processing-Services_pid355?spm=a2700.9161164.2.3.1a514e02P9QMcs",
          },
          {
            name: "Apparel Stock",
            originalLink: "http://www.alibaba.com/Apparel-Stock_pid333?spm=a2700.9161164.2.4.1a514e02P9QMcs",
          },
          {
            name: "Boys Clothing",
            originalLink: "http://www.alibaba.com/Boy%E2%80%99s-Clothing_pid100003186?spm=a2700.9161164.2.5.1a514e02P9QMcs",
          },
          {
            name: "Costumes",
            originalLink: "http://www.alibaba.com/Costumes_pid100003238?spm=a2700.9161164.2.6.1a514e02P9QMcs",
          },
          {
            name: "Ethnic Clothing",
            originalLink: "http://www.alibaba.com/Ethnic-Clothing_pid100003239?spm=a2700.9161164.2.7.1a514e02P9QMcs",
          },
        ],
      },
      {
        name: "Textile and Leather Product",
        subcategories: [
          {
            name: "Down & Feather",
            originalLink: "http://www.alibaba.com/Down-Feather_pid100000938?spm=a2700.9161164.2.23.1a514e02P9QMcs",
          },
          {
            name: "Fabric",
            originalLink: "http://www.alibaba.com/Fabric_pid423?spm=a2700.9161164.2.24.1a514e02P9QMcs",
          },
          {
            name: "Fiber",
            originalLink: "http://www.alibaba.com/Fiber_pid415?spm=a2700.9161164.2.25.1a514e02P9QMcs",
          },
          {
            name: "Fur",
            originalLink: "http://www.alibaba.com/Fur_pid100000936?spm=a2700.9161164.2.26.1a514e02P9QMcs",
          },
          {
            name: "Grey Fabric",
            originalLink: "http://www.alibaba.com/Grey-Fabric_pid414?spm=a2700.9161164.2.27.1a514e02P9QMcs",
          },
          {
            name: "Home Textile",
            originalLink: "http://www.alibaba.com/Home-Textile_pid405?spm=a2700.9161164.2.28.1a514e02P9QMcs",
          },
        ],
      },
      {
        name: "Fashion Accesories",
        subcategories: [
          {
            name: "Belt Accessories",
            originalLink: "http://www.alibaba.com/Belt-Accessories_pid33913?spm=a2700.9161164.2.43.1a514e02P9QMcs",
          },
          {
            name: "Belts",
            originalLink: "http://www.alibaba.com/Belts_pid32801?spm=a2700.9161164.2.44.1a514e02P9QMcs",
          },
          {
            name: "Gloves and Mittens",
            originalLink: "http://www.alibaba.com/Gloves-Mittens_pid324?spm=a2700.9161164.2.47.1a514e02P9QMcs",
          },
          {
            name: "Scar, Hat and Glove Sets",
            originalLink: "http://www.alibaba.com/Scarf-Hat-Glove-Sets_pid33906?spm=a2700.9161164.2.48.1a514e02P9QMcs",
          },
          {
            name: "Hats and Caps",
            originalLink: "http://www.alibaba.com/Hats-Caps_pid327?spm=a2700.9161164.2.49.1a514e02P9QMcs",
          },
          {
            name: "Scarves and Shawls",
            originalLink: "http://www.alibaba.com/Scarves-Shawls_pid325?spm=a2700.9161164.2.50.1a514e02P9QMcs",
          },
        ],
      },
      {
        name: "Timepieces, Jewelry, Eyewear ",
        subcategories: [
          {
            name: "Eyewear",
            originalLink: "http://www.alibaba.com/Eyewear_pid1512?spm=a2700.9161164.2.61.1a514e02P9QMcs",
          },
          {
            name: "Jewelry",
            originalLink: "http://www.alibaba.com/Jewelry_pid1509?spm=a2700.9161164.2.62.1a514e02P9QMcs",
          },
          {
            name: "Watches",
            originalLink: "http://www.alibaba.com/Watches_pid1511?spm=a2700.9161164.2.63.1a514e02P9QMcs",
          },
          {
            name: "Eyeglasses Frames",
            originalLink: "http://www.alibaba.com/Eyeglasses-Frames_pid361250?spm=a2700.9161164.2.64.1a514e02P9QMcs",
          },
          {
            name: "Sunglasses",
            originalLink: "http://www.alibaba.com/Sunglasses_pid33902?spm=a2700.9161164.2.65.1a514e02P9QMcs",
          },
          {
            name: "Sports Eyewear",
            originalLink: "http://www.alibaba.com/Sports-Eyewear_pid361230?spm=a2700.9161164.2.66.1a514e02P9QMcs",
          },
        ],
      },
    ],
  },
  {
    name: "Auto and Transportation",
    subcategories: [
      {
        name: "Vehicles and Accessories",
        subcategories: [
          {
            name: "Automobiles",
            originalLink: "http://www.alibaba.com/Automobiles_pid100002871?spm=a2700.9161164.3.2.f18a4e02SziFsc",
          },
          {
            name: "Trailers",
            originalLink: "https://www.alibaba.com/catalog/trailers_cid205775307?spm=a2700.9161164.3.3.f18a4e02SziFsc",
          },
          {
            name: "Trucks",
            originalLink: "https://www.alibaba.com/catalog/trucks_cid205776714?spm=a2700.9161164.3.4.f18a4e02SziFsc",
          },
          {
            name: "Golf Carts",
            originalLink: "https://www.alibaba.com/catalog/golf-carts_cid205777508?spm=a2700.9161164.3.5.f18a4e02SziFsc",
          },
          {
            name: "Marine Parts & Accessories",
            originalLink: "https://www.alibaba.com/catalog/marine-parts-accessories_cid205777512?spm=a2700.9161164.3.6.f18a4e02SziFsc",
          },
          {
            name: "Truck Parts & Accessories",
            originalLink: "https://www.alibaba.com/catalog/truck-parts-accessories_cid205777709?spm=a2700.9161164.3.7.f18a4e02SziFsc",
          },
        ],
      },
    ],
  },
  {
    name: "Bags, Shoes & Accessories",
    subcategories: [
      {
        name: "Luggage, Bags & Cases",
        subcategories: [
          {
            name: "Luggage Making Materials",
            originalLink: "http://www.alibaba.com/Bag-Luggage-Making-Materials_pid3807?spm=a2700.9161164.4.2.f18a4e02SziFsc",
          },
          {
            name: "Bag Parts and Accessories",
            originalLink: "http://www.alibaba.com/Bag-Parts-Accessories_pid152409?spm=a2700.9161164.4.3.f18a4e02SziFsc",
          },
          {
            name: "Business Bags and Cases",
            originalLink: "http://www.alibaba.com/Business-Bags-Cases_pid3801?spm=a2700.9161164.4.4.5bbd4e029umpyO",
          },
          {
            name: "Digital Gears and Camera Bags",
            originalLink: "http://www.alibaba.com/Digital-Gear-Camera-Bags_pid3802?spm=a2700.9161164.4.5.5bbd4e029umpyO",
          },
          {
            name: "Hand Bags and Messenger Bags",
            originalLink: "http://www.alibaba.com/Handbags-Messenger-Bags_pid100002610?spm=a2700.9161164.4.6.5bbd4e029umpyO",
          },
          {
            name: "Luggage and Travel Bags",
            originalLink: "http://www.alibaba.com/Luggage-Travel-Bags_pid152404?spm=a2700.9161164.4.7.5bbd4e029umpyO",
          },
        ],
      },
      {
        name: "Shoes and Accessories",
        subcategories: [
          {
            name: "Baby Shoes",
            originalLink: "https://www.alibaba.com/catalog/baby-shoes_cid205781701?spm=a2700.9161164.4.23.5bbd4e029umpyO",
          },
          {
            name: "Childrens Shoes",
            originalLink: "http://www.alibaba.com/Children%E2%80%99s-Shoes_pid32212?spm=a2700.9161164.4.24.5bbd4e029umpyO",
          },
          {
            name: "Dance Shoes",
            originalLink: "http://www.alibaba.com/Dance-Shoes_pid100003552?spm=a2700.9161164.4.25.5bbd4e029umpyO",
          },
          {
            name: "Mens Shoes",
            originalLink: "http://www.alibaba.com/Men%E2%80%99s-Shoes_pid100001615?spm=a2700.9161164.4.27.5bbd4e029umpyO",
          },
          {
            name: "Womens Shoes",
            originalLink: "http://www.alibaba.com/Women%E2%80%99s-Shoes_pid100001606?spm=a2700.9161164.4.38.5bbd4e029umpyO",
          },
          {
            name: "Shoe Part and Accessories",
            originalLink: "http://www.alibaba.com/Shoe-Parts-Accessories_pid32210?spm=a2700.9161164.4.30.5bbd4e029umpyO",
          },
        ],
      },
    ],
  },
  {
    name: "Electronics",
    subcategories: [
      {
        name: "Consumer Electronic",
        subcategories: [
          {
            name: "Computer Hardware and Software",
            originalLink: "https://www.alibaba.com/catalog/computer-hardware-software_cid7?spm=a2700.9161164.5.2.5bbd4e029umpyO",
          },
          {
            name: "Accessories and Parts",
            originalLink: "http://www.alibaba.com/Accessories-Parts_pid100009632?spm=a2700.9161164.5.4.5bbd4e029umpyO",
          },
          {
            name: "Mobile Phones",
            originalLink: "http://www.alibaba.com/Mobile-Phones_pid5090301?spm=a2700.9161164.5.12.5bbd4e029umpyO",
          },
          {
            name: "Earphone and Headphone",
            originalLink: "http://www.alibaba.com/Earphone-Headphone_pid63705?spm=a2700.9161164.5.13.5bbd4e029umpyO",
          },
          {
            name: "Power Banks",
            originalLink: "http://www.alibaba.com/Power-Banks_pid100009648?spm=a2700.9161164.5.14.5bbd4e029umpyO",
          },
          {
            name: "Digital Camera",
            originalLink: "http://www.alibaba.com/Digital-Camera_pid1909?spm=a2700.9161164.5.15.5bbd4e029umpyO",
          },
          {
            name: "Television",
            originalLink: "http://www.alibaba.com/Television_pid634?spm=a2700.9161164.5.18.5bbd4e029umpyO",
          },
          {
            name: "Cables",
            originalLink: "http://www.alibaba.com/Cables_pid100009644?spm=a2700.9161164.5.19.5bbd4e029umpyO",
          },
        ],
      },
      {
        name: "Home Appliance",
        subcategories: [
          {
            name: "Air Conditioning Appliances",
            originalLink: "http://www.alibaba.com/Air-Conditioning-Appliances_pid100000050?spm=a2700.9161164.5.26.5bbd4e029umpyO",
          },
          {
            name: "Cleaning Appliances",
            originalLink: "http://www.alibaba.com/Cleaning-Appliances_pid100000013?spm=a2700.9161164.5.27.5bbd4e029umpyO",
          },
          {
            name: "Kitchen Appliances",
            originalLink: "http://www.alibaba.com/Kitchen-Appliances_pid100000011?spm=a2700.9161164.5.32.5bbd4e029umpyO",
          },
          {
            name: "Refrigerators and Freezers",
            originalLink: "http://www.alibaba.com/Refrigerators-Freezers_pid100000026?spm=a2700.9161164.5.35.5bbd4e029umpyO",
          },
          {
            name: "Air Conditioners",
            originalLink: "http://www.alibaba.com/Air-Conditioners_pid601?spm=a2700.9161164.5.39.5bbd4e029umpyO",
          },
          {
            name: "Cooking Appliances",
            originalLink: "http://www.alibaba.com/Cooking-Appliances_pid100000017?spm=a2700.9161164.5.43.5bbd4e029umpyO",
          },
        ],
      },
      {
        name: "Security and Protection",
        subcategories: [
          {
            name: "Locks and Keys",
            originalLink: "https://www.alibaba.com/catalog/locks-keys_cid205779704?spm=a2700.9161164.5.47.5bbd4e029umpyO",
          },
          {
            name: "Personal Protective Equipment",
            originalLink: "https://www.alibaba.com/catalog/personal-protective-equipment_cid205781605?spm=a2700.9161164.5.48.5bbd4e029umpyO",
          },
          {
            name: "Alarm",
            originalLink: "http://www.alibaba.com/Alarm_pid3008?spm=a2700.9161164.5.50.5bbd4e029umpyO",
          },
          {
            name: "CCTV Products",
            originalLink: "http://www.alibaba.com/CCTV-Products_pid3011?spm=a2700.9161164.5.51.5bbd4e029umpyO",
          },
          {
            name: "CCTV Camera",
            originalLink: "http://www.alibaba.com/CCTV-Camera_pid301102?spm=a2700.9161164.5.61.5bbd4e029umpyO",
          },
          {
            name: "Self Defense Supplies",
            originalLink: "http://www.alibaba.com/Self-Defense-Supplies_pid3019?spm=a2700.9161164.5.59.5bbd4e029umpyO",
          },
        ],
      },
    ],
  },
  {
    name: "Electrical Equipment & Supplies",
    subcategories: [
      {
        name: "Solar Energy Products",
        originalLink: "https://www.alibaba.com/catalog/solar-energy-products_cid1007?spm=a2700.9161164.6.2.5bbd4e029umpyO",
      },
      {
        name: "Batteries",
        originalLink: "http://www.alibaba.com/Batteries_pid528?spm=a2700.9161164.6.3.5bbd4e029umpyO",
      },
      {
        name: "Electrical Instruments",
        originalLink: "https://www.alibaba.com/catalog/electrical-instruments_cid153703?spm=a2700.9161164.6.4.5bbd4e029umpyO",
      },
      {
        name: "Connectors and Terminals",
        originalLink: "http://www.alibaba.com/Connectors-Terminals_pid14190406?spm=a2700.9161164.6.6.5bbd4e029umpyO",
      },
      {
        name: "Motors",
        originalLink: "https://www.alibaba.com/catalog/motors_cid100006831?spm=a2700.9161164.6.9.5bbd4e029umpyO",
      },
      {
        name: "Electrical Supplies",
        originalLink: "https://www.alibaba.com/catalog/electrical-supplies_cid205782207?spm=a2700.9161164.6.10.5bbd4e029umpyO",
      },
      {
        name: "Generators",
        originalLink: "http://www.alibaba.com/Generators_pid141902?spm=a2700.9161164.6.14.5bbd4e029umpyO",
      },
    ],
  },
  {
    name: "Gifts, Sports & Toys",
    subcategories: [
      {
        name: "Sports and Entertainment",
        subcategories: [
          {
            name: "Amusement Park",
            originalLink: "http://www.alibaba.com/Amusement-Park_pid100005611?spm=a2700.9161164.7.2.5bbd4e029umpyO",
          },
          {
            name: "Fitness and Body Building",
            originalLink: "http://www.alibaba.com/Fitness-Body-Building_pid100005259?spm=a2700.9161164.7.4.5bbd4e029umpyO",
          },
          {
            name: "Gambling",
            originalLink: "http://www.alibaba.com/Gambling_pid100005300?spm=a2700.9161164.7.5.5bbd4e029umpyO",
          },
          {
            name: "Indoor Sports",
            originalLink: "http://www.alibaba.com/Indoor-Sports_pid100005334?spm=a2700.9161164.7.7.5bbd4e029umpyO",
          },
          {
            name: "Outdoor Sports",
            originalLink: "http://www.alibaba.com/Outdoor-Sports_pid100005431?spm=a2700.9161164.7.10.5bbd4e029umpyO",
          },
          {
            name: "Musical Instruments",
            originalLink: "http://www.alibaba.com/Musical-Instruments_pid100005383?spm=a2700.9161164.7.8.5bbd4e029umpyO",
          },
          {
            name: "Camping and Hiking",
            originalLink: "http://www.alibaba.com/Camping-Hiking_pid100005433?spm=a2700.9161164.7.18.5bbd4e029umpyO",
          },
        ],
      },
      {
        name: "Gifts and Crafts",
        subcategories: [
          {
            name: "Art and Collectible",
            originalLink: "http://www.alibaba.com/Art-Collectible_pid100002989?spm=a2700.9161164.7.23.5bbd4e029umpyO",
          },
          {
            name: "Craft",
            originalLink: "https://www.alibaba.com/catalog/crafts_cid205776912?spm=a2700.9161164.7.24.5bbd4e029umpyO",
          },
          {
            name: "Festive and Party Supplies",
            originalLink: "http://www.alibaba.com/Festive-Party-Supplies_pid100002992?spm=a2700.9161164.7.27.5bbd4e029umpyO",
          },
          {
            name: "Gift Sets",
            originalLink: "http://www.alibaba.com/Gift-Sets_pid1735?spm=a2700.9161164.7.29.5bbd4e029umpyO",
          },
          {
            name: "Holiday Gifts",
            originalLink: "http://www.alibaba.com/Holiday-Gifts_pid100002999?spm=a2700.9161164.7.30.5bbd4e029umpyO",
          },
          {
            name: "Home Decoration",
            originalLink: "http://www.alibaba.com/Home-Decoration_pid0?spm=a2700.9161164.7.31.5bbd4e029umpyO",
          },
          {
            name: "Souvenirs",
            originalLink: "http://www.alibaba.com/Souvenirs_pid1742?spm=a2700.9161164.7.40.5bbd4e029umpyO",
          },
          {
            name: "Wedding Decorations and Gifts",
            originalLink: "http://www.alibaba.com/Wedding-Decorations-Gifts_pid100002990?spm=a2700.9161164.7.42.5bbd4e029umpyO",
          },
        ],
      },
      {
        name: "Toys and Hobbies",
        subcategories: [
          {
            name: "Action Figure",
            originalLink: "http://www.alibaba.com/Action-Figure_pid2621?spm=a2700.9161164.7.44.5bbd4e029umpyO",
          },
          {
            name: "Baby Toys",
            originalLink: "http://www.alibaba.com/Baby-Toys_pid100001622?spm=a2700.9161164.7.45.5bbd4e029umpyO",
          },
          {
            name: "Dolls",
            originalLink: "http://www.alibaba.com/Dolls_pid2605?spm=a2700.9161164.7.49.5bbd4e029umpyO",
          },
          {
            name: "Educational Toys",
            originalLink: "http://www.alibaba.com/Educational-Toys_pid100001625?spm=a2700.9161164.7.50.5bbd4e029umpyO",
          },
          {
            name: "Electronic Toys",
            originalLink: "http://www.alibaba.com/Electronic-Toys_pid100001629?spm=a2700.9161164.7.51.5bbd4e029umpyO",
          },
          {
            name: "Toy Accessories",
            originalLink: "http://www.alibaba.com/Toy-Accessories_pid2614?spm=a2700.9161164.7.61.5bbd4e029umpyO",
          },
        ],
      },
    ],
  },
  {
    name: "Health & Beauty",
    subcategories: [
      {
        name: "Health and Medical",
        subcategories: [
          {
            name: "Health Care Supplies",
            originalLink: "http://www.alibaba.com/Health-Care-Supplies_pid100009356?spm=a2700.9161164.8.6.5bbd4e029umpyO",
          },
          {
            name: "Health Care Supplements",
            originalLink: "http://www.alibaba.com/Health-Care-Supplement_pid100009254?spm=a2700.9161164.8.5.5bbd4e029umpyO",
          },
        ],
      },
    ],
  },
  {
    name: "Home, Lights & Construction",
  },
  {
    name: "Machinery, Industrial Parts & Tools",
  },
  {
    name: "Metallurgy, Chemicals",
  },
  {
    name: "Rubber & Plastics",
  },
  {
    name: "Packaging, Advertising & Office",
  },
];

export const bankAccountDetails = {
  bankName: "BPI",
  accountNo: "0201051347",
  accountName: "Market Place Inc",
};

export const bankAccountDetailsUnionBank = {
  bankName: "UnionBank",
  accountNo: "001610006991",
  accountName: "Market Place Inc",
};

export const warehouseDetails = {
  address: "8 Premiere St. Menry Village, Quezon City",
  contact: "09999988782 / 09999959580",
  office: "Unit 903-B Tektite West Tower Philippine Stock Exchange Building, Ortigas Center, Pasig City, Philippines 1605",
  email: "info@marketdeals.com.ph",
};

export const blackList = [
  "medical",
  "Medical",
  "Physical Therapy Equipments",
  "General Medical Supplies",
  "Clinical Analytical Instruments",
  "Emergency Medical Supplies & Training",
  "Tracheal Cannula",
  "Injection & Puncture Instrument",
  "Rehabilitation Therapy Supplies",
  "medical supplies",
];

export const errors = {
  NETWORK_ERROR: "An unexpected error occurred. Please try again",
  DUPLICATE_USER: "Email has already been used.",
};
