import { SIGN_IN_USER, UPDATE_AUTH_USER, UPDATE_USER_DETAILS } from "./constants";

const initialState = {
  isLoggedIn: false,
  user: null,
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SIGN_IN_USER:
      return {
        ...state,
        isLoggedIn: true,
        user: payload,
      };
    case UPDATE_AUTH_USER:
      return {
        ...state,
        user: payload,
      };
    case UPDATE_USER_DETAILS:
      return {
        ...state, 
        user: {...state.user, ...payload}
      }
    default:
      return state;
  }
}
