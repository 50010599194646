import { createTheme, Theme } from "@material-ui/core";

export const primaryColor = "#034921";
export const secondaryColor = "#DE651A";
const blackColor = "#000";
const whiteColor = "#fff";

const hexToRgb = (input) => {
  input = input + "";
  input = input.replace("#", "");
  let hexRegex = /[0-9A-Fa-f]/g;
  if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
    throw new Error("input is not a valid hex color.");
  }
  if (input.length === 3) {
    let first = input[0];
    let second = input[1];
    let last = input[2];
    input = first + first + second + second + last + last;
  }
  input = input.toUpperCase();
  let first = input[0] + input[1];
  let second = input[2] + input[3];
  let last = input[4] + input[5];
  return parseInt(first, 16) + ", " + parseInt(second, 16) + ", " + parseInt(last, 16);
};

export const boxShadow = {
  boxShadow:
    "0 10px 30px -12px rgba(" +
    hexToRgb(blackColor) +
    ", 0.42), 0 4px 25px 0px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 8px 10px -5px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)",
};

export const boxShadow2 = {
  boxShadow:
    "0 5px 15px -6px rgba(" +
    hexToRgb(blackColor) +
    ", 0.42), 0 2px 13px 0px rgba(" +
    hexToRgb(blackColor) +
    ", 0.12), 0 4px 5px -3px rgba(" +
    hexToRgb(blackColor) +
    ", 0.2)",
};

export const defaultTheme = (theme: Theme) =>
  createTheme({
    palette: {
      primary: {
        main: primaryColor,
        // contrastText: "#FFF",
      },
      secondary: {
        main: secondaryColor,
        // contrastText: primaryColor,
      },
      background: {
        // paper: "red",
        // default: "blue",
      },
    },
    overrides: {
      MuiFormLabel: {
        root: {
          color: primaryColor,
          // fontWeight: 500,
        },
      },
      MuiInput: {
        root: {
          padding: theme.spacing(1),
        },
      },
      MuiButton: {
        label: {
          textTransform: "none",
          whiteSpace: "nowrap",
          fontWeight: 300,
        },
      },
      MuiListItemIcon: {
        root: {
          minWidth: 32,
          maxWidth: 32,
        },
      },
      MuiAppBar: {
        colorPrimary: {
          backgroundColor: whiteColor,
        },
      },
    },
    // typography: {
    //   fontWeightRegular: 300,
    // },
  });
