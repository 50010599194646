import React, { useEffect, useState } from "react";
import { IconButton, Badge, Box, Popover, Grid, Typography, Divider, Button } from "@material-ui/core";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { useSelector, useDispatch } from "react-redux";
import { Fragment } from "react";
import { Close } from "@material-ui/icons";
import { addToCart, getCart, setCart } from "../../modules/User/actions";
import { Add, Remove } from "@material-ui/icons";
import { useHistory } from "react-router-dom";

const Cart = () => {
  const [cartItemsCount, setCartItemsCount] = useState(0);
  const { cart } = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    // const checkCart = localStorage.getItem("cart");

    // if (checkCart) {
    //   dispatch(setCart(JSON.parse(checkCart)));
    // }
    dispatch(getCart());
  }, [dispatch]);

  useEffect(() => {
    let count = 0;
    cart.products?.forEach((item) => {
      count += item.quantity;
    });

    setCartItemsCount(count);
  }, [cart]);

  useEffect(() => {
    if (cart.length) {
      localStorage.setItem("cart", JSON.stringify(cart));
    } else {
      localStorage.removeItem("cart");
    }
  }, [cart]);
  return (
    <Box position="fixed" bottom="0px" right="0px" padding="1rem">
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        disableScrollLock
      >
        <Box p={2} minHeight="450px" width={"320px"} position="relative">
          <Box p={1} display="flex" alignItems="center" justifyContent="space-between">
            <Typography>Your Cart</Typography>
            <IconButton onClick={() => setAnchorEl(null)}>
              <Close />
            </IconButton>
          </Box>
          <Divider />
          <Box height="1rem"></Box>
          <Grid container align="center" spacing={1}>
            {cart.products?.map((item, iI) => {
              // console.log("item", item);
              return (
                <Fragment key={item._id}>
                  <Grid item xs={3}>
                    {item?.product?.media[0] && <img alt={`Item No. ${iI + 1}`} style={{ width: "100%" }} src={item?.product?.media[0]} />}
                  </Grid>
                  <Grid item xs={9}>
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                      <Typography>{item.product?.product?.name || item?.product?.name}</Typography>
                      <Box display="flex" alignItems="center">
                        <IconButton
                          disabled={item.quantity === 1}
                          onClick={() => {
                            // let newCart = cart.map((i) => {
                            //   if (i._id === item._id) {
                            //     return {
                            //       ...item,
                            //       cartCount: item.cartCount - 1,
                            //     };
                            //   }
                            //   return i;
                            // });
                            // localStorage.setItem(
                            //   "cart",
                            //   JSON.stringify(newCart)
                            // );
                            // dispatch(setCart(newCart));
                            dispatch(
                              addToCart({
                                products: [
                                  {
                                    product: item.product._id,
                                    quantity: -1,
                                  },
                                ],
                              })
                            );
                          }}
                        >
                          <Remove />
                        </IconButton>
                        <Typography>{item.quantity}</Typography>
                        <IconButton
                          onClick={() => {
                            // let newCart = cart.map((i) => {
                            //   if (i._id === item._id) {
                            //     return {
                            //       ...item,
                            //       cartCount: item.cartCount + 1,
                            //     };
                            //   }
                            //   return i;
                            // });
                            // localStorage.setItem(
                            //   "cart",
                            //   JSON.stringify(newCart)
                            // );
                            // dispatch(setCart(newCart));
                            dispatch(
                              addToCart({
                                products: [
                                  {
                                    product: item.product._id,
                                    quantity: 1,
                                  },
                                ],
                              })
                            );
                          }}
                        >
                          <Add />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                </Fragment>
              );
            })}
          </Grid>
          <Box position="absolute" bottom="0px" left="0px" p={1} width="100%">
            <Button
              disabled={!cart?.products.length}
              style={{ borderRadius: "0px" }}
              variant="contained"
              color="secondary"
              fullWidth
              onClick={() => history.push("/checkout")}
            >
              Checkout
            </Button>
          </Box>
        </Box>
      </Popover>
      <IconButton aria-describedby={id} onClick={handleClick}>
        <Badge badgeContent={cartItemsCount} color="secondary">
          <ShoppingCartIcon style={{ fontSize: "35px" }} />
        </Badge>
      </IconButton>
    </Box>
  );
};

export default Cart;
