export const HOME = "/";
export const LOCALHOME = "/home";
export const DASHBOARD = "/dashboard";
export const SIGN_UP = "/sign-up";
export const SIGN_IN = "/sign-in";
export const FORGOT_PASSWORD = "/forgot-password";
export const RESET_PASSWORD = "/reset-password/:id";
export const APPLY_MERCHANT = "/apply-merchant";
export const CHANGE_PASSWORD = "/change-password";
export const PRODUCT_DETAILED = "/products/:productName/:productId";
export const PRODUCTS_BY_CATEGORY = "/products/category/:categoryName/:categoryId";
export const CHECKOUT = "/checkout";
export const MY_ADDRESS_BOOK = "/my-address-book";
export const SHARE_AND_EARN = "/share-and-earn";
export const AFFILIATE_LINK = "/affiliate/:affiliateId";
export const AFFILIATE = "/affiliate";
export const LOCAL = "/local";

//Blog page for SEO
// export const BLOGQUERY = "/blog/:query"

export const USERS = "/users";
export const MERCHANTS = "/merchants";
export const ADMIN = "/admin";
export const MESSAGES = "/messages";
export const CHATREP = "/chatrep";

export const SETTINGS = "/settings";
export const FEES = "/fees";
export const BANNERS = "/banners";
export const WITHDRAWALS = "/withdrawals";
export const INTERNATIONAL_PRODUCTS = "/international-products";
export const HELPCENTER = "/helpcenter";
export const INVOICE_PAYMENTS = "/payments";

export const NEW = "/new";
export const EDIT = "/edit";
export const ALL = "/all";
export const MASS_UPDATE = "/mass-update";
export const DOWNLOAD = "/download";
export const UPLOAD = "/upload";
export const SUGGESTION = "/suggestions";
export const CATEGORIES = "/categories";
export const PLACES = "/places";
export const PRODUCTS = "/products";
export const PRODUCT_CATEGORIES = "/product-categories";
export const VARIANTS = "/variants";
export const PRODUCT_VARIANTS = "/product-variants";
export const PRODUCT_REVIEWS = "/product-reviews";
export const CUSTOMERS = "/customers";
export const ORDER = "/order";
export const ORDERS = "/orders";
export const ADDRESS = "/address";
export const SELLER_POLICIES = "/seller-policies";
export const SELLER_POLICY_CATEGORIES = "/seller-policy-categories";

export const ALIBABA_PRODUCTS_HOME = "/international-products";
export const ALIBABA_PRODUCT = "/international-products/product/:id";
export const ALIBABA_PRODUCTS_CATEGORY = "/international-products/category/:id";
export const ALIBABA_PRODUCT_SEARCH = "/international-products/search/:query";
export const ALIBABA_SUPPLIER_SEARCH = "/international-suppliers/search/:query";
export const ALIBABA_PRODUCT_NSEARCH = "/international-products/nsearch/:query/:subq";
export const ALIBABA_SHOP = "/international-products/shop/:id";
export const ALIBABA_PRODUCT_CS_VIEW = "/international-products/p/:id";

export const ESTIMATE = "/estimates/:id";
export const INVOICE = "/invoices/:id";
export const INVOICEPAYMENTS = "/invoice-payments/:id";
export const DEALS = "/deals";
export const VERIFICATIONSUCCESS = "/verificationsuccess";

// export const ACCOUNT = "/account";
export const ACCOUNT = HOME;
export const MY_ACCOUNT = "/my-account";
export const PROFILE = "/profile";
export const DELETE_ACCOUNT = "/delete-account";

// MERCHANT TO PUBLIC
export const STORE_FRONT = "/shop/:slug/:storeId";

// MERCHANT PRIVATE MODULE
export const MY_STORE = "/my-store";
export const FINANCIALS = "/financials";
export const INCOME = "/income";
export const BALANCE = "/balance";
export const WALLET = "/wallet";
export const HOME2 = "/home";
export const DASH = "/dashboard";
export const DATA_CENTER = "/datacenter";
export const PERFORMANCE = "/performance";
export const OVERVIEW = "/overview";
export const DIAGNOSIS = "/diagnosis";

// MERCHAT CUSTOMER CHAT
export const CHAT = "/chat-with/:userId";
export const CHAT2 = "/chat-with";
export const ADMINCHAT = "/adminchat";

// PAYMENTS
export const PAYMENTS = "/payments";
export const PAYMENTS_PAYPAL = PAYMENTS + "/paypal";
export const PAYMENT_AFFILIATE_MEMBERSHIP = PAYMENTS + "/affiliate-membership";
export const PAYMENT_AFFILIATE_REDIRECT_ = PAYMENTS + "/processing-payment";

// PRice match
export const PRICE_MATCH = "/price-match";
// SEARCH
export const SEARCH = "/search";

export const QUOTATIONS = "/quotations";
export const MERCHANT_CHATS = "/merchant-chats";
export const DOCUMENTS = "/documents";
export const WAREHOUSE = "/warehouse";
