import { SET_MERCHANT_ORDERS, SET_MERCHANT_ORDERS_LIST, SET_ORDER, SET_ORDERS } from "./constants";

const initialState = {
  order: null,
  orders: [],
  merchantOrders: [],
  merchantOrdersList: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ORDER:
      return { ...state, order: payload };
    case SET_ORDERS:
      return { ...state, orders: payload };
    case SET_MERCHANT_ORDERS:
      return { ...state, merchantOrders: payload };
    case SET_MERCHANT_ORDERS_LIST:
      return { ...state, merchantOrdersList: payload };
    default:
      return state;
  }
}
