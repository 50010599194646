import { Accordion, AccordionSummary, Typography, Button } from "@material-ui/core";
import { AttachFile, Chat, Close, Description, Edit, ExpandMore, Help, Send } from "@material-ui/icons";
import { styled } from "@material-ui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { format } from "timeago.js";
import { isImage, sanitizeFileString } from "../../library/utilities";
import { getAutoResponseSeller, getUserChatMessages, getUserChats, markConversationAsRead, sendUserMessage } from "../../modules/AlibabaProducts/actions";
import { closeChat, openChat } from "../../modules/App/actions";
import { getHasMessagesWith } from "../../modules/Chat/actions";
import { uploadTempSingle } from "../../modules/Upload/actions";
// import io from "socket.io-client";
import ImageEditor from "@toast-ui/react-image-editor";
import { PhotoConsumer, PhotoProvider, PhotoSlider } from "react-photo-view";
import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore, onSnapshot, doc, query, setDoc, addDoc, where, orderBy, limit, updateDoc } from "firebase/firestore";
import { config } from "../../library/common/firebase";
import {
  AccordionDetails,
  Backdrop,
  Badge,
  CircularProgress,
  Dialog,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Paper,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  TextField,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@mui/material";

// const socketPoint = process.env.REACT_APP_SOCKET_URL;
// let socket = io(socketPoint);

const app = initializeApp(config());
const db = getFirestore(app);

function ChatBox() {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { chatBox } = useSelector((state) => state.app);
  const { userChats, userChatMessages, selectedConvMerchant } = useSelector((state) => state.alibaba);
  const [showFaq, setShowFaq] = useState(true);
  const isMerchantMode = user && user.merchant;
  const [newMessages, setNewMessages] = useState(userChats?.filter((a) => a.hasNew).length);
  const [firebaseMessages, setFirebaseMessages] = useState(null);
  const [firebaseConvs, setFirebaseConvs] = useState([]);
  const [selectedConv, setSelectedConv] = useState(null);
  const [usermessages, setusermessages] = useState([]);
  const [selectedConvIndex, setSelectedConvIndex] = useState();
  const handleToggle = () => {
    dispatch(chatBox ? closeChat() : openChat());
  };
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  console.log("firebase=>", firebaseConvs);

  // useEffect(() => {
  //   if (user) {
  //     socket.emit("user", user.userId);
  //     socket.on("incoming-message", (msg) => {
  //       console.log("userchats: ", userChats);
  //       console.log(msg);
  //       console.log(user);
  //       console.log(isMerchantMode);
  //       dispatch(getUserChats(isMerchantMode ? msg.merchant : user.userId));
  //       console.log(userChats);
  //       setNewMessages(userChats?.filter((a) => a.hasNew).length);
  //     });
  //     dispatch(getUserChats(isMerchantMode ? user.merchant._id : user.userId));
  //   }
  // }, [user, socket, db]);

  useEffect(() => {
    if (user) {
      const q = query(collection(db, "merchantmessages", user?.userId, "merchants"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        const m = [];
        querySnapshot.forEach((doc) => {
          m.push(doc.data());
        });
        setFirebaseConvs(m);
        //setFirebaseMessages(m)
      });

      return () => unsub();
    }
  }, [user, db]);

  useEffect(() => {
    if (selectedConv) {
      const q = query(collection(db, "merchantmessages", user?.userId, "merchants", selectedConv.storeName, "messages"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        const mgs = [];
        querySnapshot.forEach((doc) => {
          mgs.push(doc.data());
        });
        console.log(mgs);
        setusermessages(mgs);
      });
      return () => unsub();
    }
  }, [selectedConv, db]);

  useEffect(() => {
    if (selectedConvMerchant) {
      setSelectedConv(selectedConvMerchant);
      const q = query(collection(db, "merchantmessages", user?.userId, "merchants", selectedConvMerchant.storeName, "messages"));
      const unsub = onSnapshot(q, (querySnapshot) => {
        const mgs = [];
        querySnapshot.forEach((doc) => {
          mgs.push(doc.data());
        });
        console.log(mgs);
        setusermessages(mgs);
      });
      return () => unsub();
    }
  }, [selectedConvMerchant, db]);

  const handleSelectConv = async (c) => {
    const docRef = doc(db, "merchantmessages", user?.userId, "merchants", c?.storeName);
    await updateDoc(docRef, {
      hasNew: false,
    });
    setSelectedConv(c);
  };

  return (
    <div style={{ zIndex: 1999, position: "fixed", bottom: 0, left: mobile ? null : 100, width: chatBox ? (mobile ? "100%" : 650) : mobile ? "100%" : 200 }}>
      <Accordion expanded={chatBox} onChange={handleToggle} TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <div style={{ display: "flex" }}>
            <Badge variant="dot" badgeContent={newMessages} color="info">
              <Chat />
            </Badge>

            <Typography style={{ marginLeft: 20 }}>Messages</Typography>
          </div>
          {chatBox && !user && <NoUser />}
        </AccordionSummary>
        <AccordionDetails style={{ background: "#eff0f5", minHeight: 500 }}>
          <Grid container spacing={1}>
            <Grid item xs={4}>
              {user && (
                <List>
                  {firebaseConvs
                    ?.sort((a, b) => b.timestamp - a.timestamp)
                    .map((c, index) => (
                      <ListItem button key={index} onClick={() => handleSelectConv(c)} selected={selectedConv === c}>
                        <ListItemText
                          primary={
                            <Badge variant="dot" badgeContent={c.hasNew ? 1 : 0} color="info">
                              <Typography variant="body2" style={{ fontWeight: c.hasNew ? "bold" : "normal" }}>
                                {c.storeName}
                              </Typography>
                            </Badge>
                          }
                          secondary={<Typography variant="caption">{c.lastMessage.substring(0, 20)}...</Typography>}
                        />
                      </ListItem>
                    ))}
                </List>
              )}
            </Grid>
            <Grid item xs={8}>
              {!user && <NoUserContent />}
              {usermessages.length > 0 && (
                <>
                  <UserMessages selectedConv={selectedConv} usermessages={usermessages} showFaq={showFaq} setShowFaq={setShowFaq} />
                  <MessageBox selectedConv={selectedConv} showFaq={showFaq} setShowFaq={setShowFaq} />
                </>
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default ChatBox;

const StyledInputBaseMessage = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    background: "#fff",
    borderRadius: 20,
    padding: theme.spacing(1, 1, 1, 2),
    // vertical padding + font size from searchIcon
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

export const MessageBox = ({ selectedConv, setShowFaq }) => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const dispatch = useDispatch();
  const { userChats, userChatMessages } = useSelector((state) => state.alibaba);
  //const merchant = userChats.filter((a) => a._id === userChatMessages[0].conversationId)[0]
  const { user } = useSelector((state) => state.auth);
  console.log("user=> ", user);

  //   const isMerchantMode = Object.values(user.merchant).length > 0;
  const isMerchantMode = user.hasOwnProperty("merchant");
  const sender = isMerchantMode && user.userId === message.merchant ? true : false;

  const handleSubmit = () => {
    if (!message) return;
    const request = {
      merchant: selectedConv.merchant,
      user: sender ? selectedConv.merchant : selectedConv.user,
      message,
      sender: sender ? "merchant" : "user",
      messageType: "text",
      storeName: selectedConv.storeName,
    };
    dispatch(sendUserMessage(request, sender));
    // socket.emit("sending-message-user-merchant", request);
    setMessage("");
  };
  const inputRef = React.useRef();
  const handleFileUploadClick = () => {
    inputRef.current.click();
  };
  const [imageEditor, setImageEditor] = useState(null);
  const onFileChange = async (e) => {
    const files = [...e.target.files];
    console.log(e.target.files[0]);
    setLoading(true);
    const uploaded_file = await dispatch(uploadTempSingle(e.target.files[0]));
    console.log(uploaded_file);
    if (isImage(uploaded_file.location)) {
      setImageEditor(uploaded_file);
      return setLoading(false);
    }

    const newRequest = {
      merchant: selectedConv.merchant,
      user: isMerchantMode ? selectedConv.merchant : selectedConv.user,
      message: isImage(uploaded_file.key) ? "Sent a photo" : "Sent a file",
      sender: isMerchantMode ? "merchant" : "user",
      messageType: "image",
      storeName: selectedConv.storeName,
      media: uploaded_file.location,
    };
    dispatch(sendUserMessage(newRequest, isMerchantMode));
    // socket.emit("sending-message-user-merchant", newRequest);
    setLoading(false);
    /* const uploadedfiles = await Promise.all(files.map(async (file) => {
            return await dispatch(uploadTempSingle(file))
        }))
        await Promise.all(uploadedfiles.map(async (f) => {
            console.log(f)
            const newRequest = {
                merchant: merchant.merchant,
                user: isMerchantMode ? merchant.members[1] :user.userId, 
                message: isImage(f.key) ? 'Sent a photo' : 'Sent a file' ,
                sender: isMerchantMode ? 'merchant' : 'user',
                messageType: 'image', 
                storeName: merchant.storeName,
                media: f.location
            }
            dispatch(sendUserMessage(newRequest, isMerchantMode))
            socket.emit("sending-message-user-merchant", newRequest)

        }))
        setLoading(false) */
  };
  useEffect(() => {
    if (selectedConv.merchant) {
      dispatch(getAutoResponseSeller(selectedConv.merchant));
    }
  }, [selectedConv.merchant]);

  const myTheme = {
    // "header.display": "none",
    "common.bi.image": "https://www.marketdeals.com.ph/static/media/logo_2.f2efe5e4.png",
    "common.bisize.width": "173px",
    "common.bisize.height": "80px",
    "loadButton.display": "none",
    "downloadButton.display": "none",
  };

  /* useEffect(() =>{
        if(imageEditor) {
            $('.tui-image-editor-download-btn').replaceWith('<button class="tui-image-editor-save-btn" >Save</button>')
        }
    }, [imageEditor]) */
  const imageEditorRef = useRef();

  function dataURLtoFile(dataUrl, fileName) {
    var arr = dataUrl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], fileName, { type: mime });
  }
  const handleSendImage = async () => {
    const editorInstance = imageEditorRef.current.getInstance();

    const imageDataURL = editorInstance.toDataURL();
    const mimeType = imageDataURL.split(";base64,")[0].split(":")[1];
    let fileExt = "";
    if (mimeType === "image/png") {
      fileExt = ".png";
    } else if (mimeType === "image/jpg") {
      fileExt = ".jpg";
    } else {
      fileExt = ".jpeg";
    }
    const fileName = Date.now().toString();
    console.log(fileName + fileExt);
    const blob = dataURLtoFile(imageDataURL, fileName + fileExt);
    setLoading(true);
    const uploaded_file = await dispatch(uploadTempSingle(blob));
    console.log(uploaded_file);
    const newRequest = {
      merchant: selectedConv.merchant,
      user: isMerchantMode ? selectedConv.merchant : user.userId,
      message: "Sent a photo",
      sender: isMerchantMode ? "merchant" : "user",
      messageType: "image",
      storeName: selectedConv.storeName,
      media: uploaded_file.location,
    };
    dispatch(sendUserMessage(newRequest, isMerchantMode));
    // socket.emit("sending-message-user-merchant", newRequest);
    setImageEditor(null);
    setLoading(false);
  };

  const handleEnterPress = (e) => {
    if (e.charCode === 13 && !e.shiftKey) {
      // enter key pressed
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div>
      <Backdrop open={loading} style={{ zIndex: 10000 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {imageEditor && (
        <Dialog open={Boolean(imageEditor)} onClose={() => setImageEditor(null)} fullWidth maxWidth="lg" disableEnforceFocus style={{ zIndex: 1000000 }}>
          <Button variant="contained" color="primary" onClick={handleSendImage} style={{ position: "absolute", top: 20, right: 80, zIndex: 1000 }}>
            {loading ? `Uploading Image...` : `Send Image`}
          </Button>
          <Button variant="contained" onClick={() => setImageEditor(null)} style={{ position: "absolute", top: 20, right: loading ? 250 : 200, zIndex: 1000 }}>
            Cancel
          </Button>
          <ImageEditor
            ref={imageEditorRef}
            includeUI={{
              loadImage: {
                path: imageEditor.location,
                name: imageEditor.originalname,
              },
              menu: ["crop", "flip", "rotate", "draw", "text"],
              initMenu: "draw",
              theme: myTheme,
              uiSize: {
                width: "1200px",
                height: "700px",
              },
              menuBarPosition: "right",
            }}
            cssMaxHeight={500}
            cssMaxWidth={800}
            selectionStyle={{
              cornerSize: 20,
              rotatingPointOffset: 70,
            }}
            usageStatistics={true}
          />
        </Dialog>
      )}
      <SpeedDial
        FabProps={{
          size: "small",
        }}
        ariaLabel="options"
        icon={<SpeedDialIcon openIcon={<Edit />} />}
        sx={{ position: "absolute", bottom: 5, left: mobile ? 5 : 170 }}
      >
        <SpeedDialAction icon={<AttachFile />} tooltipTitle={"Attach File"} onClick={handleFileUploadClick} />
        <SpeedDialAction icon={<Help />} tooltipTitle={"Frequently Asked Questions"} onClick={() => setShowFaq(true)} />
      </SpeedDial>
      <input ref={inputRef} style={{ display: "none" }} id="icon-button-file" type="file" onChange={onFileChange} />
      <div style={{ position: "absolute", bottom: 0, width: mobile ? null : 410, display: "flex", alignItems: "center" }}>
        <StyledInputBaseMessage
          multiline
          sx={{ borderRadius: 2 }}
          placeholder="Type your message here..."
          fullWidth
          value={message}
          onChange={({ target }) => setMessage(target.value)}
          onKeyPress={handleEnterPress}
        />
        <IconButton color="info" size="small" onClick={handleSubmit}>
          <Send />
        </IconButton>
      </div>
    </div>
  );
};

export const UserMessages = ({ showFaq, setShowFaq, usermessages, selectedConv }) => {
  const lastChat = useRef();
  const dispatch = useDispatch();
  const { userChats, userChatMessages, autoResponse } = useSelector((state) => state.alibaba);
  const { user } = useSelector((state) => state.auth);
  //const merchant = userChats.filter((a) => a._id === userChatMessages[0].conversationId)[0]
  //   const isMerchantMode = Object.values(user.merchant).length > 0;
  const isMerchantMode = user.hasOwnProperty("merchant");
  const tone = new Audio(
    "https://www.mobilesringtones.com/static/p/ringtones/2018/03/07/17571/17571.mp3?title=17571_download_iphone_notification_ringtone_iphone_sms_ringtones.mp3"
  );
  useEffect(() => {
    if (lastChat.current) {
      // tone.play();
      lastChat.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [lastChat?.current, usermessages]);

  useEffect(() => {
    // tone.play();
  }, [usermessages]);

  /* useEffect(() => {
        socket.emit('user-merchant',isMerchantMode ? merchant.members[1] : user.userId, merchant.merchant, isMerchantMode ? 'merchant' : 'user')
        socket.on("incoming-message", msg => {
            dispatch(getUserChatMessages(merchant._id))
            dispatch(getUserChats(isMerchantMode ? user.merchant._id : user.userId))
        })
    }, [socket]) */

  const onFaqClick = async (item) => {
    const newRequest = {
      merchant: selectedConv.merchant,
      user: isMerchantMode ? selectedConv.merchant : user.userId,
      message: item.question,
      sender: "user",
      messageType: "text",
      storeName: selectedConv.storeName,
    };
    await dispatch(sendUserMessage(newRequest, isMerchantMode));
    // socket.emit("sending-message-user-merchant", newRequest);
    const nextReq = {
      merchant: selectedConv.merchant,
      user: isMerchantMode ? selectedConv.merchant : user.userId,
      message: item.message,
      sender: "merchant",
      messageType: "text",
      storeName: selectedConv.storeName,
    };
    await dispatch(sendUserMessage(nextReq, isMerchantMode));
    // socket.emit("sending-message-user-merchant", nextReq);
  };
  return (
    <div style={{ maxHeight: 450, overflow: "scroll", paddingRight: 10 }}>
      {usermessages?.map((message, index) => (
        <UserMessage message={message} key={index} isMerchantMode={isMerchantMode} />
      ))}
      {autoResponse && showFaq && (
        <div>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 10 }}>
            <Typography variant="caption" component="h6" style={{ marginTop: 10 }}>
              Frequently Asked Questions
            </Typography>
            <IconButton size="small" onClick={() => setShowFaq(false)}>
              <Close />
            </IconButton>
          </div>
          {autoResponse?.faqItems?.map((item, index) => (
            <Button
              key={index}
              variant="contained"
              style={{ borderRadius: 20, background: "#fff", color: "#000", marginBottom: 10, marginRight: 10, textTransform: "none" }}
              onClick={() => onFaqClick(item)}
            >
              {item.question}
            </Button>
          ))}
        </div>
      )}
      <div ref={lastChat} />
    </div>
  );
};

const UserMessage = ({ message, isMerchantMode }) => {
  const [imageViewer, setImageViewer] = useState(null);
  const [visible, setVisible] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const { user } = useSelector((state) => state.auth);
  const sender = isMerchantMode && user.userId === message.merchant ? "merchant" : "user";

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: message.sender === sender ? "end" : "start" }}>
      <Paper
        sx={{
          width: "fit-content",
          borderRadius: 2,
          textTransform: "none",
          background: message.sender === sender ? "#114920" : "#fff",
          color: message.sender === sender ? "#fff" : "#333",
          paddingX: 2,
          paddingY: 1,
          marginY: 1,
          borderTopLeftRadius: message.sender === sender ? 8 : 0,
          borderTopRightRadius: message.sender === sender ? 0 : 8,
        }}
      >
        {message.messageType === "text" ? (
          message.message.split("\n").map((str) => (
            <Typography key={str} variant="caption" style={{ lineHeight: 1.2 }} component="p">
              {str}
            </Typography>
          ))
        ) : isImage(message?.media) ? (
          <img
            alt="img"
            src={message?.media}
            style={{
              height: 150,
              width: "auto",
              objectFit: "cover",
              borderRadius: 5,
            }}
            onClick={() => {
              setVisible(true);
              setImageViewer([message?.media]);
            }}
            //onClick={() => window.open(message?.attachments, '_blank')}
          />
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Description fontSize="small" />
            <Typography variant="caption" style={{ fontWeight: "bold", cursor: "pointer" }} onClick={() => window.open(message?.media, "_blank")}>
              {sanitizeFileString(message?.media)}
            </Typography>
          </div>
        )}
      </Paper>
      {imageViewer && (
        <PhotoSlider
          images={imageViewer.map((item) => ({ src: item }))}
          visible={visible}
          onClose={() => setVisible(false)}
          index={photoIndex}
          onIndexChange={setPhotoIndex}
        />
      )}
    </div>
  );
};

const ImageViewer = ({ images }) => {
  return (
    <PhotoProvider>
      {images.map((item, index) => (
        <PhotoConsumer key={index} src={item} intro={item}>
          <img src={item} alt="chat_image" />
        </PhotoConsumer>
      ))}
    </PhotoProvider>
  );
};

const NoUser = () => {
  return (
    <div style={{ marginLeft: 100 }}>
      <Typography>Market Deals</Typography>
    </div>
  );
};
const NoUserContent = () => {
  const dispatch = useDispatch();
  return (
    <div style={{ height: 300, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      <Chat style={{ fontSize: 50, marginBottom: 30 }} />
      <Typography style={{ textAlign: "center", fontSize: 18, marginBottom: 30 }}>Once you start a conversation, you'll see it listed here</Typography>
      <Button size="large" variant="contained" color="primary" onClick={() => dispatch(closeChat())} component={Link} to="/sign-in">
        Sign in
      </Button>
    </div>
  );
};
