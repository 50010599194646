export const firebaseConfigLive = {
  apiKey: "AIzaSyDlgF-rznC4Hj4px2W-_j99QCuZR9hDTKE",
  authDomain: "marketdeals-9294c.firebaseapp.com",
  projectId: "marketdeals-9294c",
  storageBucket: "marketdeals-9294c.appspot.com",
  messagingSenderId: "625754153586",
  appId: "1:625754153586:web:fa24765899d53f7c22d8eb",
  measurementId: "G-B0WEXEB2GN",
};

export const firebaseConfigDev = {
  apiKey: "AIzaSyCw4POaFPUulpre7qBJqn0e2BNf-r0D1ek",
  authDomain: "market-deals-dev.firebaseapp.com",
  projectId: "market-deals-dev",
  storageBucket: "market-deals-dev.appspot.com",
  messagingSenderId: "681472380964",
  appId: "1:681472380964:web:c626d6305276b4272c48b1",
  measurementId: "G-PF448B5YPK",
};

export const config = () => {
  return process.env.NODE_ENV === "production" ? firebaseConfigLive : firebaseConfigLive;
};
