export const saveToStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const fetchFromStorage = (key) => {
  const value = JSON.parse(localStorage.getItem(key));
  return value ? value : null
};

export const removeFromStorage = (key) => {
  localStorage.removeItem(key);
};

export const clearStorage = () => {
  localStorage.clear();
};
