import { SET_INVOICE, REMOVE_SELECTED_INVOICE, SET_INVOICES, SET_PAYMENTS, SET_SELECTED_PAYMENT } from "./constants";


const initialState = {
    invoices: [],
    selectedInvoice : {},
    payments: [], 
    selectedPayment: null
}

export default function reducer(state = initialState, {type, payload}) {
    switch (type) {
        case SET_SELECTED_PAYMENT: {
          return{ 
            ...state, 
            selectedPayment: payload
          }
        }
        case SET_PAYMENTS: {
          return {
            ...state, 
            payments: payload
          }
        }
        case SET_INVOICES: {
            return {
              ...state,
              invoices: payload,
            };
        }
        case SET_INVOICE: {
            return {
              ...state,
              selectedInvoice: payload,
            };
        }
        case REMOVE_SELECTED_INVOICE: {
            return {
              ...state,
              selectedInvoice: {} ,
            };
        }
        default:
            return state;
    }
}