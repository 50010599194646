import {
  APP_LOADED,
  ASYNC_ACTION_ERROR,
  ASYNC_ACTION_FINISH,
  ASYNC_ACTION_START,
  SET_SHOW_SIDEBAR,
  SET_PROGRESS,
  SET_SHOW_PROGRESS,
  SET_PREVIEW,
  TOGGLE_SHOW_LIVE_SIDEBAR,
  SET_ALERT_DIALOG,
  TOGGLE_NAVIGATION_LAYOUT,
  SET_APP_SETTINGS,
  SET_BANNERS,
  SET_DOCUMENTS,
  SET_MERCHANT_CHATS,
  SET_SELECTED_MERCHANT_CHAT,
  SET_SELECTED_CHAT,
  OPEN_CHATBOX,
  CLOSE_CHATBOX,
  SET_ADMIN_VOUCHERS,
  LOADING_START,
  LOADING_FINISH,
} from "./constants";

const initialState = {
  isLoading: false,
  loading: false,
  error: null,
  initialized: false,
  showSidebar: true,
  showProgress: false,
  progress: 0,
  preview: false,
  showLiveSidebar: true,
  alertDialog: null,
  navigationLayout: "top",
  appSettings: {},
  banners: [],
  documents: [],
  merchantConversations: [],
  selectedConversation: [],
  selectedChat: {},
  chatBox: false,
  adminVouchers: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ADMIN_VOUCHERS:
      return {
        ...state,
        adminVouchers: payload,
      };
    case OPEN_CHATBOX:
      return {
        ...state,
        chatBox: true,
      };
    case CLOSE_CHATBOX:
      return {
        ...state,
        chatBox: false,
      };
    case SET_SELECTED_CHAT:
      return {
        ...state,
        selectedChat: payload,
      };
    case SET_MERCHANT_CHATS:
      return {
        ...state,
        merchantConversations: payload,
      };
    case SET_SELECTED_MERCHANT_CHAT:
      return {
        ...state,
        selectedConversation: payload,
      };
    case SET_DOCUMENTS:
      return {
        ...state,
        documents: payload,
      };
    case SET_BANNERS:
      return {
        ...state,
        banners: payload,
      };
    case ASYNC_ACTION_START:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ASYNC_ACTION_FINISH:
      return {
        ...state,
        loading: false,
        error: null,
        showProgress: false,
      };
    case ASYNC_ACTION_ERROR:
      return {
        ...state,
        loading: false,
        error: payload,
        showProgress: false,
      };
    case APP_LOADED:
      return {
        ...state,
        initialized: true,
        showProgress: false,
      };
    case SET_SHOW_SIDEBAR:
      return { ...state, showSidebar: payload };
    case SET_SHOW_PROGRESS:
      return { ...state, showProgress: payload };
    case SET_PROGRESS:
      return { ...state, progress: payload };
    case SET_PREVIEW:
      return { ...state, preview: payload };
    case TOGGLE_SHOW_LIVE_SIDEBAR:
      return { ...state, showLiveSidebar: !state.showLiveSidebar };
    case SET_ALERT_DIALOG:
      return { ...state, alertDialog: payload };
    case TOGGLE_NAVIGATION_LAYOUT:
      return {
        ...state,
        navigationLayout: state.navigationLayout === "top" ? "right" : "top",
      };
    case SET_APP_SETTINGS:
      return {
        ...state,
        appSettings: payload,
      };
    case LOADING_START:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_FINISH:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
