import { initializeApp } from "firebase/app";
import { collection, getDocs, getFirestore, onSnapshot, doc, query, setDoc, addDoc, where, orderBy, limit, updateDoc } from "firebase/firestore";
import axios from "../../library/axios";
import { config } from "../../library/common/firebase";
import { SET_CONVERSATIONS, SET_SELECTED_CONVERSATION, SET_MESSAGES, ADD_TO_MESSAGES, MARK_AS_READ } from "./constants";
import _ from "lodash";

/* live */
/*  const firebaseConfig = {
    apiKey: "AIzaSyDlgF-rznC4Hj4px2W-_j99QCuZR9hDTKE",
    authDomain: "marketdeals-9294c.firebaseapp.com",
    projectId: "marketdeals-9294c",
    storageBucket: "marketdeals-9294c.appspot.com",
    messagingSenderId: "625754153586",
    appId: "1:625754153586:web:fa24765899d53f7c22d8eb",
    measurementId: "G-B0WEXEB2GN"
  }; */

const app = initializeApp(config());
const db = getFirestore(app);

export const getAllConversations = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/conversations`);
      dispatch(setConversations(response.data.conversations));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const getMyConversations = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`/conversations/${id}`);
      dispatch(setConversations(response.data.conversations));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const getMyConversationWithSupplier = (id, supplier) => {
  return async (dispatch) => {
    try {
      dispatch(setMessages([]));
      const response = await axios.get(`/conversations/supplier/${id}/${supplier}`);
      dispatch(setMessages(response.data.messages));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const sendNewMessage = (id, data) => {
  return async (dispatch) => {
    try {
      console.log(data);
      const convref = doc(db, "conversations", `${data.members[0]} - ${data.supplier}`);
      const supplierRef = doc(db, "conversations", `${data.members[0]} - ${data.supplier}`, "messages", Date.now().toString());
      const messageRef = doc(db, "conversations", data.members[0], "supplier", data.supplier, "messages", Date.now().toString());
      if (data?.userInfo) {
        data.userInfo = _.pick(data.userInfo, ["_id", "email", "firstName", "lastName", "mobile", "userId", "occupation"]);
        await setDoc(convref, {
          supplier: data.supplier,
          userInfo: data.userInfo,
          timestamp: Date.now(),
        });
      }
      await updateDoc(convref, {
        timestamp: Date.now(),
        ...data,
      });
      const savedConv = await setDoc(supplierRef, {
        ...data,
        lastMessage: data.message,
        timestamp: Date.now(),
      });
      /* const savedMessage = await setDoc(messageRef, {
                ...data, 
                timestamp: Date.now()
            }) */
      const response = await axios.post(`/conversations/${id}`, data);
      //dispatch(addToMessage(response.data.message))
      if (data.hasNew && data.userInfo) {
        axios.post(`/messages/notifications`, {
          text: `You have a new message from ${data.userInfo.email}`,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
};
export const sendNewMessageWithImage = (id, form) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/conversations/${id}`, form);
      dispatch(addToMessage(response.data.message));
    } catch (error) {
      console.log(error.message);
    }
  };
};

export const markMessageAsRead = (conv) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/conversations/${conv._id}`, { ...conv, hasNew: false });
      dispatch(markAsRead(conv._id));
    } catch (error) {
      console.log(error.message);
    }
  };
};

const setConversations = (payload) => {
  return {
    type: SET_CONVERSATIONS,
    payload,
  };
};

export const setMessages = (payload) => {
  return {
    type: SET_MESSAGES,
    payload,
  };
};

export const setSelectedConversation = (payload) => {
  return {
    type: SET_SELECTED_CONVERSATION,
    payload,
  };
};

export const addToMessage = (payload) => {
  return {
    type: ADD_TO_MESSAGES,
    payload,
  };
};

export const markAsRead = (id) => {
  return {
    type: MARK_AS_READ,
    payload: id,
  };
};
