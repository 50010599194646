import { SET_MERCHANT, SET_MERCHANTS, SET_PROFILE, SET_USERS, SET_CART, SET_PROVINCES, SET_CITIES, SET_ADDRESSES, SET_AREAS, ADD_USER, UPDATE_USER, SET_SELECTED_MERCHANT, SET_INVOICES, SET_QUOTATIONS } from "./constants";

const initialState = {
  profile: null,
  users: [],
  merchants: [],
  selectedMerchant : null,
  cart: {
    products: [],
  },
  provinces: [],
  cities: [],
  areas: [],
  addresses: [],
  invoices: [],
  quotations: []
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_INVOICES: 
      return {
        ...state, invoices: payload
      }
    case SET_QUOTATIONS: 
      return {
        ...state, quotations: payload
      }
    case SET_SELECTED_MERCHANT: 
      return {
        ...state, selectedMerchant: payload
      }
    case SET_PROFILE:
      return { ...state, profile: payload || null };
    case SET_USERS:
      return { ...state, users: payload || [] };
    case ADD_USER:
      return {...state, users: [...state.users, payload]}
    case UPDATE_USER:
      return {...state, users: [...state.users.filter(a => a.id !== payload.id), payload]}
    case SET_MERCHANTS:
      return { ...state, merchants: payload || [] };
    case SET_MERCHANT: {
      let merchants = [...state.merchants];
      let index = merchants.findIndex((a) => a._id === payload._id);
      if (index > -1) {
        console.log(index)
        console.log({ ...merchants[index], ...payload })
        merchants[index] = { ...merchants[index], ...payload };
      } else {
        merchants.push(payload);
      }
      return {
        ...state,
        merchants,
      };
    }
    case SET_ADDRESSES:
      return { ...state, addresses: payload || [] };

    case SET_CART: {
      return { ...state, cart: payload || state.cart };
    }
    case SET_PROVINCES: {
      return { ...state, provinces: payload };
    }

    case SET_CITIES: {
      return { ...state, cities: payload };
    }
    case SET_AREAS: {
      return { ...state, areas: payload };
    }
    default:
      return state;
  }
}
