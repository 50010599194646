import { SET_PRODUCTS_BY_CATEGORIES } from "../../components/Home/constants";
import {
  SET_PRODUCT,
  SET_PRODUCTS,
  SET_PRODUCT_CATEGORIES,
  SET_PRODUCT_REVIEWS,
  SET_BRANDS,
  SET_PRICE_MATCHES,
  SET_SEARCHED_PRODUCTS,
  SET_PRODUCT_PLACES_CATEGORIES,
  SET_RELATED_PRODUCTS,
  SET_NEW_PROD,
  SET_PRODUCTS_WITH_CATEGORIES,
} from "./constants";

const initialState = {
  product: null,
  products: [],
  productsByCategory: {},
  productCategories: [],
  productPlacesCategories: [],
  productReviews: [],
  brands: [],
  priceMatches: [],
  searchedProducts: [],
  relatedProducts: [],
  newProd: {},
  productsWithCategory: {},
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_PRODUCTS_WITH_CATEGORIES: {
      return {
        ...state,
        productsWithCategory: payload,
      };
    }

    case SET_NEW_PROD: {
      return { ...state, newProd: payload };
    }
    case SET_PRODUCT: {
      let products = [...state.products];
      let index = products.findIndex((a) => a._id === payload._id);
      if (index > -1) {
        products[index] = { ...products[index], ...payload };
      } else {
        products.push(payload);
      }
      return {
        ...state,
        product: payload,
        products,
      };
    }
    case SET_PRODUCTS:
      return { ...state, products: payload || [] };
    case SET_PRODUCT_CATEGORIES:
      return { ...state, productCategories: payload || [] };
    case SET_PRODUCT_PLACES_CATEGORIES:
      return { ...state, productPlacesCategories: payload || [] };

    case SET_PRODUCTS_BY_CATEGORIES: {
      return {
        ...state,
        products: payload,
      };
    }
    case SET_PRODUCT_REVIEWS:
      return { ...state, productReviews: payload };

    case SET_BRANDS: {
      return {
        ...state,
        brands: payload,
      };
    }
    case SET_PRICE_MATCHES: {
      return {
        ...state,
        priceMatches: payload,
      };
    }

    case SET_SEARCHED_PRODUCTS: {
      return {
        ...state,
        searchedProducts: payload,
      };
    }

    case SET_RELATED_PRODUCTS: {
      return {
        ...state,
        relatedProducts: payload,
      };
    }
    default:
      return state;
  }
}
