export const SET_AUTH = "SET_AUTH";
export const SIGN_IN_USER = "SIGN_IN_USER";
export const SIGN_OUT_USER = "SIGN_OUT_USER";
export const UPDATE_AUTH_USER = "UPDATE_AUTH_USER";

export const ACCESS_TOKEN = "ACCESS_TOKEN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const UPDATE_USER_DETAILS = "UPDATE_USER_DETAILS"
