export const SET_ALIBABA_CATEGORIES = "SET_ALIBABA_CATEGORIES";
export const SET_J4U = "SET_J4U";
export const SET_ALIBABA_SELECTED_PRODUCT = "SET_ALIBABA_SELECTED_PRODUCT";
export const SET_ALIBABA_SELECTED_CATEGORY = "SET_ALIBABA_SELECTED_CATEGORY";
export const SET_ALIBABA_SELECTED_SHOP = "SET_ALIBABA_SELECTED_SHOP";
export const SET_ALIBABA_SPOTLIGHT = "SET_ALIBABA_SPOTLIGHT";

export const CRYPTO_SECRET = "MARKETDEALS"
export const VERIFIED_SUPPLIER_PARAMS = "&productTag=1200000228"
export const PAID_SAMPLES_PARAMS = "&param_order=IFS-1&freeSample=1"
export const READY_TO_SHIP_PARAMS = "&productTag=1200000228"
export const SET_MESSAGES = "SET_MESSAGES"
export const SET_MESSAGES_WITH_EXISTING = "SET_MESSAGES_WITH_EXISTING"
export const SET_HAS_CHATS_WITH = "SET_HAS_CHATS_WITH"
export const INQUIRY_INTRO = "Great day! Thank you for reaching out to us. You are inquiring about the product that you wish to purchase."
export const ORDER_CONFIRM = "Is this order confirmed?"

export const SET_AUTO_RESPONSE = "SET_AUTO_RESPONSE"

export const SET_USER_CHATS = "SET_USER_CHATS"
export const SET_USER_CHAT_MESSAGES = "SET_USER_CHAT_MESSAGES"
export const ADD_TO_USER_CHAT_MESSAGES = "ADD_TO_USER_CHAT_MESSAGES"

export const SET_MERCHANT_PRODUCTS = "SET_MERCHANT_PRODUCTS"
export const SET_INTERNATIONAL_PRODUCT = "SET_INTERNATIONAL_PRODUCT"

export const OPEN_INTERNATIONAL_CHATBOX = "OPEN_INTERNATIONAL_CHATBOX"
export const CLOSE_INTERNATIONAL_CHATBOX = "CLOSE_INTERNATIONAL_CHATBOX"
export const SET_INTERNATIONAL_CATEGORIES = "SET_INTERNATIONAL_CATEGORIES"
export const SET_NEW_ARRIVALS = "SET_NEW_ARRIVALS"
export const SET_SELECTED_CONV_MERCHANT = "SET_SELECTED_CONV_MERCHANT"



