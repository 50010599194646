import { SET_SELLER_POLICIES, SET_SELLER_POLICY, SET_SELLER_POLICY_CATEGORIES, SET_SELLER_POLICY_CATEGORY } from "./constants";

const initialState = {
  sellerPolicyCategory: null,
  sellerPolicyCategories: [],
  sellerPolicy: null,
  sellerPolicies: [],
};

export default function reducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_SELLER_POLICY_CATEGORIES:
      return { ...state, sellerPolicyCategories: payload };
    case SET_SELLER_POLICY_CATEGORY:
      return { ...state, sellerPolicyCategory: payload };
    case SET_SELLER_POLICIES:
      return { ...state, sellerPolicies: payload };
    case SET_SELLER_POLICY:
      return { ...state, sellerPolicy: payload };
    default:
      return state;
  }
}
